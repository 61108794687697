import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import adminNotesSlice from "./slices/adminNotesSlice";
import notificationSlice from "./slices/notificationSlice";
import feedbackSlice from "./slices/feedbackSlice";
import jobTitleSlice from "./slices/Job/jobTitleSlice";
import citiesSlice from "./slices/citiesSlicee";
import cvTemplatesSlice from "./slices/cvTemplatesSlice";
import bannerSlice from "./slices/bannerSlice";
import applicantStageSlice from "./slices/Job/applicantStageSlice";
import jobFieldSlice from "./slices/Job/jobFieldSlice";
import jobsSlice from "./slices/Job/jobsSlice";
import seekerSlice from "./slices/Users/seekerSlice";
import employerFieldSlice from "./slices/Users/employerFieldSlice";
import employerSlice from "./slices/Users/employerSlice";
import userSlice from "./slices/Users/userSlice";
import authSlice from "./slices/authSlice";
import profileSlice from "./slices/profileSlice";
import appSlice from "./slices/appSlice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    auth: authSlice,
    profile: profileSlice,
    adminNotes: adminNotesSlice,
    notification: notificationSlice,
    feedback: feedbackSlice,
    jobTitle: jobTitleSlice,
    cities: citiesSlice,
    cvTemplates: cvTemplatesSlice,
    banner: bannerSlice,
    applicantStage: applicantStageSlice,
    jobField: jobFieldSlice,
    jobs: jobsSlice,
    seeker: seekerSlice,
    employerField: employerFieldSlice,
    employer: employerSlice,
    user: userSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);
