import React from "react";
import { TextField, Autocomplete } from "@mui/material";

export const SelectYesNo = (props) => {
  const options = [
    { id: "true", label: "Yes" },
    { id: "false", label: "No" },
  ];

  return (
    <Autocomplete
      {...props}
      options={options}
      getOptionLabel={(option) => option?.label || ""}
      isOptionEqualToValue={(option, value) => {
        if (!option || !value) return false;
        return option.id === value.id;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label || "Select"}
          required={props.required}
          size="small"
        />
      )}
    />
  );
};
