import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import Table from "../components/EmployerField/Table";
export const EmployerField = () => {
  return (
    <>
      <Slider />
      <Table />
    </>
  );
};
