import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import Tabel from "../components/Admins/Table";

export const Admins = () => {
  return (
    <>
      <Slider />
      <Tabel />
    </>
  );
};
