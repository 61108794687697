import { Box, CircularProgress } from '@mui/material';
import React from 'react'

const LoadingCircle = () => {
    return (
        <Box
            sx={{
                mx: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <CircularProgress />
        </Box>
    )
}

export default LoadingCircle;