import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  is_drawer_opened: true,
  collapse: false,
  pending_admin_notes: 0,
  pending_employers: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDrawer: (state, action) => {
      state.is_drawer_opened = !state.is_drawer_opened;
    },
    setCollapse: (state, action) => {
      if (action.payload) {
        state.collapse = action.payload;
      } else {
        state.collapse = !state.collapse;
      }
    },
    setPendingStats: (state, { payload }) => {
      if (payload) {
        state.pending_admin_notes = payload.admin_notes;
        state.pending_employers = payload.pending_employers;
      }
    },
  },
});

export const { setDrawer, setCollapse, setPendingStats } = appSlice.actions;

export default appSlice.reducer;
