import { useEffect } from "react";
import { Box, Avatar, Chip } from "@mui/material";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import ModalInfo from "./Modal/ModalInfo";
import ModalDelete from "./Modal/ModalDelete";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  Jobs,
  setJobApplicantDialog,
  setJobApplicantFilters,
} from "../../app/slices/Job/jobsSlice";
import { ModalProfileSeeker } from "../ProfileSeeker/ModalProfileSeeker";
import { setSeekerProfileDialog } from "../../app/slices/Users/seekerSlice";
const TabelApplicant = () => {
  const dispatch = useDispatch();
  const {
    jobApplicant: { data, loading, filters, total },
  } = useSelector((state) => state.jobs);

  const {
    seekerProfile: { dialog },
  } = useSelector((state) => state.seeker);

  const handleOpenURL = (url) => {
    if (url) {
      let completeURL = url;
      // Check if the URL contains a protocol, if not, add 'https://' as a default
      if (!url?.includes("http://") && !url?.includes("https://")) {
        completeURL = `https://${url}`;
      }
      const newWindow = window.open();
      newWindow.location.href = completeURL;
    } else {
      toast.error(<div>CV does not exist</div>);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Avatar src={params.row?.seeker?.profile_pic} sizes={"small"} />
            <Box>
              <ColumnText
                text={params.row?.seeker?.name}
                variant="subtitle1"
                fontWeight="bold"
              />
              <ColumnText text={params.row?.seeker?.mobile} color={"gray"} />
            </Box>
          </Box>
        );
      },
    },
    {
      field: "skills",
      headerName: "Skills",
      flex: 1,
      minWidth: 250,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row?.seeker?.skills}
              variant="subtitle2"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.seeker.mobile} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      minWidth: 195,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            text={`${params.row.seeker?.city?.province?.name_en} - ${params.row.seeker?.city?.name_en}`}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "applicant_stage",
      headerName: "Stage",
      flex: 1,
      minWidth: 100,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Chip
            label={
              params?.row?.applicant_stage?.name_en
                ? params?.row?.applicant_stage?.name_en
                : "N/A"
            }
            color={
              params?.row?.applicant_stage?.name_en === "apply"
                ? "success"
                : "default"
            }
            variant="outlined"
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      minWidth: 100,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              title="User"
              color={"info"}
              onClick={() =>
                dispatch(setSeekerProfileDialog({ id: params.row.seeker.id }))
              }
              icon={<AccountBoxIcon color="info" />}
            />
            <ColumnButton
              title="Download CV"
              color={"info"}
              onClick={() => handleOpenURL(params?.row?.seeker?.cv_path)}
              icon={<ContactPageIcon color="warning" />}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(Jobs.indexJobApplicant());
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        <DataGridTable
          rowCount={total}
          rows={data}
          loading={loading}
          page={filters.page}
          columns={columns}
          pageSize={filters.take}
          onPageChange={(newPage) => {
            dispatch(
              setJobApplicantFilters({
                skip: newPage * filters.take,
                page: newPage,
              })
            );
          }}
          onPageSizeChange={(newPageSize) => {
            dispatch(
              setJobApplicantFilters({
                take: newPageSize,
              })
            );
            if (filters.skip === 0) {
              dispatch(Jobs.index());
            } else {
              dispatch(
                setJobApplicantFilters({
                  skip: 0,
                  page: 0,
                })
              );
            }
          }}
        />
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
        {dialog && (
          <ModalProfileSeeker
            handleClose={() => {
              dispatch(setSeekerProfileDialog());
            }}
          />
        )}
      </Box>
    </>
  );
};

export default TabelApplicant;
