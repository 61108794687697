import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import SelectAdminNotesStatus from "../Selects/SelectAdminNotesStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFilters,
  setFilters,
  setFiltersDialog,
} from "../../app/slices/adminNotesSlice";
export default function Filter({ fetchData, ...props }) {
  const {
    adminNotes: { filters, dialog },
  } = useSelector((state) => state.adminNotes);
  const dispatch = useDispatch();
  const handleSearch = () => {
    fetchData();
  };

  const handleRemoveSearch = () => {
    dispatch(resetFilters());
  };

  const toggleDrawer = () => (event) => {
    dispatch(setFiltersDialog());
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        mt: 8,
      }}
      role="presentation"
    >
      <Box sx={{ m: 1, position: "relative", height: "86vh" }}>
        <Typography mt={2} variant="h6">
          Filter Advancis
        </Typography>
        <SelectAdminNotesStatus
          value={filters.status}
          onChange={(e, newValue) => dispatch(setFilters({ status: newValue }))}
        />
        <Box sx={{ position: "absolute", bottom: 0, right: 0, left: 0 }}>
          <Button
            onClick={() => handleSearch()}
            sx={{ width: "100%" }}
            variant="outlined"
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            onClick={() => handleRemoveSearch()}
            color="error"
            sx={{ width: "100%", mt: 1 }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"} className="mt-5 pt-5">
        <Drawer anchor={"right"} open={dialog} onClose={toggleDrawer()}>
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
