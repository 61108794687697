import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Notifications,
  setDialog,
} from "../../../app/slices/notificationSlice";
import { Controller, useForm } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LoadingButton } from "@mui/lab";

export default function ModalInfo() {
  const dispatch = useDispatch();
  const {
    manageNotification: { dialog, loading, form },
  } = useSelector((state) => state.notification);

  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: form,
  });

  // send request
  const onSubmit = (data) => {
    let group = "";
    if (data.group === "all") {
      group = data.group;
    } else if (data.group === "guest") {
      group = "users";
    } else {
      group = "group";
    }
    console.log(data);
    dispatch(Notifications.create(data, group));
  };

  // close modal
  const handleClose = () => {
    dispatch(setDialog());
  };

  useEffect(() => {
    reset({ ...form });
  }, [form]);

  return (
    <Dialog
      open={dialog}
      onClose={() => {
        handleClose();
      }}
      maxWidth={"md"}
      sx={{
        width: "100%",
        overflow: "hidden",
        borderRadius: 10,
      }}
    >
      <DialogTitle
        color={"primary"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          borderBottom: "1px solid #bdc3c7",
        }}
      >
        <AddCircleIcon color={"primary"} />
        <Typography variant="h6" fontWeight={600} color={"primary"}>
          Send Notification
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Controller
                  name="group"
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      color="primary"
                      size="small"
                      {...field}
                      exclusive
                      onChange={(e, value) => {
                        if (value === null) {
                          setValue("group", "all");
                        } else {
                          setValue("group", value);
                        }
                      }}
                      aria-label="Platform"
                    >
                      <ToggleButton color="success" value={"all"}>
                        All
                      </ToggleButton>
                      <ToggleButton color="error" value={"admin"}>
                        Admins
                      </ToggleButton>
                      <ToggleButton color="error" value={"employer"}>
                        Employers
                      </ToggleButton>
                      <ToggleButton color="error" value={"seeker"}>
                        Seekers
                      </ToggleButton>
                      <ToggleButton color="error" value={"guest"}>
                        Guests
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="title_en"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      required
                      fullWidth
                      label="Title EN"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="title_ar"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      required
                      fullWidth
                      label="Title AR"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="body_en"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      required
                      fullWidth
                      label="Body EN"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="body_ar"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      required
                      fullWidth
                      label="Body AR"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
                sx={{ textTransform: "capitalize" }}
              >
                Close
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                sx={{ textTransform: "capitalize" }}
              >
                {form?.id ? "Edit" : "Add"}
              </LoadingButton>
            </DialogActions>
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
