import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRecoilState, useSetRecoilState } from "recoil";
import { dataTabelState } from "../../recoil/Tabel";
import { openModalInfo } from "../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
} from "../../recoil/global";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const borders_shadow = {
  borderRadius: "12px",
  boxShadow:
    "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
};

export const Header = ({
  isSearchable = true,
  title,
  btnTitle,
  handleAdd,
  handleFilter,
  handleSearch,
  value,
  ...props
}) => {
  const mobile = useMediaQuery("(max-width:576px)");
  const sizes = () => {
    if (mobile) return "small";
    return "medium";
  };
  // function add
  return (
    <Card
      elevation={0}
      sx={{
        mb: 3,
        ...borders_shadow,
      }}
    >
      <CardHeader
        avatar={props.icon}
        title={
          <Typography
            variant="h6"
            fontWeight="bold"
            color={"primary"}
            sx={{ fontSize: { xs: "0.9rem", sm: "1.25rem" }, overflow: "auto" }}
          >
            {title}
          </Typography>
        }
        action={
          btnTitle && (
            <Button
              variant="contained"
              startIcon={<IoIosAddCircleOutline size={20} />}
              size={sizes()}
              sx={{
                textTransform: "capitalize",
              }}
              color="primary"
              onClick={handleAdd}
            >
              {btnTitle}
            </Button>
          )
        }
      />
      {isSearchable ? (
        <CardContent>
          <Grid container columnSpacing={1} rowSpacing={2} sx={{ px: 1 }}>
            <Grid item xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  value={value}
                  sx={{
                    borderColor: "#fff",
                    borderRadius: "12px",
                  }}
                  onChange={handleSearch}
                  size="small"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      props.fetchData();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          props.fetchData();
                        }}
                      >
                        <AiOutlineSearch />
                      </IconButton>
                    ),
                  }}
                  placeholder="Search"
                  label="Search"
                  fullWidth
                />
                {props.isFilter && (
                  <IconButton
                    onClick={handleFilter}
                    aria-label="delete"
                    size="large"
                  >
                    <FilterAltIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      ) : null}
    </Card>
  );
};
