import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import GeneralModal from "../../GeneralModal/GeneralModal";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { Banner, setDialog } from "../../../app/slices/bannerSlice";
export default function ModalInfo() {
  const dispatch = useDispatch();
  const {
    manageBanner: { dialog, loading, form },
  } = useSelector((state) => state.banner);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [removeImage, setRemoveImage] = useState(0);

  const handleURL = (url) => {
    if (url) {
      let completeURL = url;
      // Check if the URL contains a protocol, if not, add 'https://' as a default
      if (!url?.includes("http://") && !url?.includes("https://")) {
        completeURL = `https://${url}`;
        return completeURL;
      }
    }
    return url;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
    console.log(file);
  };
  const handleRemoveImage = () => {
    setSelectedFile(null);
    if (imagePreview && (form?.attachment || !form?.attachment)) {
      setImagePreview(null);
    } else if (form?.attachment) {
      setRemoveImage(1);
    }
  };

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const formData = new FormData();
    // function api request
    if (form.id) {
      formData.append("title", data.get("title"));
      formData.append("description", data.get("description"));
      formData.append("url", handleURL(data.get("url")));
      selectedFile && formData.append("attachment", selectedFile);
      removeImage && formData.append("remove_attachment", removeImage);
      formData.append("_method", "PUT");

      dispatch(Banner.update(form.id, formData));
    } else {
      formData.append("title", data.get("title"));
      formData.append("description", data.get("description"));
      formData.append("url", handleURL(data.get("url")));
      selectedFile && formData.append("attachment", selectedFile);

      dispatch(Banner.create(formData));
    }
  };

  // close modal
  const handleClose = () => {
    dispatch(setDialog());
  };

  return (
    <GeneralModal
      maxWidth="md"
      open={dialog}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      type={form?.id ? "edit" : "add"}
      loading={loading}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography
          sx={{ mx: 2, mt: 1 }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          <ContactEmergencyIcon color={"primary"} />
          &nbsp; Banner Information
        </Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              name="title"
              label="Title"
              type="text"
              id="title"
              sx={{ minWidth: 260 }}
              defaultValue={form?.title}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              margin="normal"
              fullWidth
              name="description"
              label="description"
              type="text"
              id="description"
              sx={{ width: "100%", minWidth: 260 }}
              defaultValue={form?.description}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ mx: 2, mt: 1 }}
              variant="h5"
              fontSize={18}
              fontWeight={500}
            >
              <ContactEmergencyIcon color={"primary"} />
              &nbsp; More Information
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              margin="normal"
              fullWidth
              name="url"
              label="URL"
              type="text"
              id="url"
              sx={{ width: "100%", minWidth: 260 }}
              defaultValue={form?.url}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              margin="normal"
              component="label"
              variant="outlined"
              sx={{ width: "100%", height: 40, mt: 2 }}
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <input
                type="file"
                onChange={handleFileChange}
                onClick={(event) => {
                  event.target.value = null;
                }}
                style={{ display: "none" }}
              />
            </Button>
            {(imagePreview || (form?.attachment && !removeImage)) && (
              <Button
                margin="normal"
                color="error"
                component="label"
                variant="outlined"
                sx={{ width: "100%", height: 40, my: 2 }}
                startIcon={<DeleteIcon />}
                onClick={handleRemoveImage}
              >
                Remove File
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            {imagePreview && (
              <Box
                component={"img"}
                src={imagePreview}
                sx={{ width: "100%" }}
              ></Box>
            )}
            {form?.attachment && !imagePreview && !removeImage && (
              <Box
                component={"img"}
                src={form?.attachment}
                sx={{ width: "100%" }}
              ></Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
