import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import RouterApp from "../Router";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "../app/slices/profileSlice";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export const Layout = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.profile);
  const { is_drawer_opened } = useSelector((state) => state.app);

  useEffect(() => {
    console.log("effect");
    if (!user.info.id) {
      console.log("callback");
      dispatch(Profile.myInfo());
    }
  }, [dispatch, user.info.id]);
  console.log(user.info);

  return (
    <Box>
      <Box sx={{ display: "flex", position: "relative" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <DrawerHeader sx={{ display: user.info.id ? "flex" : "none" }} />
          <Box sx={{ ml: is_drawer_opened ? 31 : 9 }}>
            <RouterApp />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
