import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Badge,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { dataTabelState } from "../../recoil/Tabel";
import { setCollapse } from "../../app/slices/appSlice";
import { useDispatch, useSelector } from "react-redux";

export const SlidbarItem = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const setData = useSetRecoilState(dataTabelState);
  const { pending_employers, pending_admin_notes } = useSelector(
    (state) => state.app
  );
  useEffect(() => {
    console.log(currentPath);
  }, [currentPath]);

  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
        width: "calc(100% - 1rem)",
        m: "0.5rem",
        border: "1px solid #d4d4d4",
        borderRadius: "10px",
        backgroundColor: currentPath === props.path ? "#ffe0e5" : "#fff",
        overflow: "hidden",
        boxShadow: "1px 4px 10px rgba(0,0,0,.1)",
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: props.open ? "initial" : "center",
          px: 2.5,
        }}
        onClick={() => {
          // setData((old) => ({
          //   ...old,
          //   filters: {
          //     ...old.filters,
          //     search: "",
          //   },
          // }));
          props?.path
            ? navigate(props.path)
            : props.openDrop === props.index
            ? dispatch(setCollapse(null))
            : dispatch(setCollapse(props.index));
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {props.icon}
        </ListItemIcon>
        <ListItemText
          primary={props.text}
          sx={{ opacity: props.open ? 1 : 0 }}
        />
        {!props.path ? (
          props.openDrop === props.index ? (
            <ExpandLess sx={{ display: props.open ? "flex" : "none" }} />
          ) : (
            <ExpandMore sx={{ display: props.open ? "flex" : "none" }} />
          )
        ) : (
          ""
        )}
        {props.path === "/admin-notes" && (
          <Badge
            badgeContent={pending_admin_notes}
            color="primary"
            max={10000}
            sx={{
              "& .MuiBadge-badge": {
                fontSize: "0.8rem",
                height: "2.2vh",
                minWidth: "2.2vh",
              },
            }}
          />
        )}
      </ListItemButton>
      {props.child && (
        <Collapse
          in={props.openDrop === props.index}
          timeout="auto"
          unmountOnExit
          sx={{ borderTop: "2px solid #fff" }}
        >
          <List component="div" disablePadding>
            {props.child.map((item, index) => {
              return (
                <ListItemButton
                  sx={{
                    pl: props.open ? 4 : 1.4,
                    background: currentPath === item.path ? "#ffe0e5" : "#fff",
                  }}
                  key={index}
                  onClick={() => {
                    navigate(item.path);
                    // setData({
                    //   data: [],
                    //   loading: false,
                    //   dialog: false,
                    //   filters: {
                    //     skip: 0,
                    //     take: 25,
                    //     page: 0,
                    //     search: "",
                    //   },
                    // });
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                  {item.path === "/employer" && (
                    <Badge
                      badgeContent={pending_employers}
                      color="primary"
                      max={10000}
                      sx={{
                        "& .MuiBadge-badge": {
                          fontSize: "0.8rem",
                          height: "2.2vh",
                          minWidth: "2.2vh",
                        },
                        marginInlineEnd: 1,
                      }}
                    />
                  )}
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};
