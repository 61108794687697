import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { SelectJobField } from "../../Selects/SelectJobField";
import { useDispatch, useSelector } from "react-redux";
import { JobTitle, setDialog } from "../../../app/slices/Job/jobTitleSlice";
import { Controller, useForm } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DrawIcon from "@mui/icons-material/Draw";
import { LoadingButton } from "@mui/lab";
import { AdminNotes } from "../../../app/slices/adminNotesSlice";
import { setPendingStats } from "../../../app/slices/appSlice";

export default function ModalInfo({ noteId = null, noteStatus = null }) {
  const dispatch = useDispatch();
  const {
    manageJobTitle: { form, loading, dialog },
  } = useSelector((state) => state.jobTitle);

  const { pending_admin_notes, pending_employers } = useSelector(
    (state) => state.app
  );

  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: form,
  });

  // send request
  const onSubmit = (data) => {
    if (form.id) {
      dispatch(JobTitle.update(form.id, data));
    } else {
      dispatch(JobTitle.create(data));
      // update admin note status to Done
      noteId && dispatch(AdminNotes.updateStatus(noteId, { status: "done" }));

      // update pending stats
      noteStatus === "pending" &&
        dispatch(
          setPendingStats({
            admin_notes: pending_admin_notes - 1,
            pending_employers,
          })
        );
    }
  };

  // close modal
  const handleClose = () => {
    dispatch(setDialog());
  };

  useEffect(() => {
    reset({ ...form });
  }, [form]);

  return (
    <Dialog
      open={dialog}
      onClose={() => {
        handleClose();
      }}
      maxWidth={"md"}
      sx={{
        width: "100%",
        overflow: "hidden",
        borderRadius: 10,
      }}
    >
      <DialogTitle
        color={"primary"}
        id="draggable-dialog-title"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          borderBottom: "1px solid #bdc3c7",
        }}
      >
        {form?.id ? (
          <DrawIcon color={"primary"} />
        ) : (
          <AddCircleIcon color={"primary"} />
        )}
        {form?.id ? (
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Edit
          </Typography>
        ) : (
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Add
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="name_en"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        required
                        fullWidth
                        label="Name EN"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="name_ar"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        required
                        fullWidth
                        label="Name AR"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="job_field_id"
                    control={control}
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <SelectJobField
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("job_field_id", newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  Close
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  sx={{ textTransform: "capitalize" }}
                >
                  {form?.id ? "Edit" : "Add"}
                </LoadingButton>
              </DialogActions>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
