import paramsFactory from "../../../utils/paramsFactory";
import api from "../../apiConfig";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/admin/employer?${p}`);
  },
  create(data) {
    return api.post(`/api/admin/employer`, data);
  },
  update(id, data) {
    return api.post(`/api/admin/employer/${id}?_method=PUT`, data);
  },
  updateStatus(status) {
    return api.post(`/api/admin/employer/reject-accept`, status);
  },
  delete(id) {
    return api.delete(`/api/admin/employer/${id}`);
  },
};
