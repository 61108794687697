import React from "react";
import GeneralModal from "../../GeneralModal/GeneralModal";
import TabelApplicant from "../TabelApplicant";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setJobApplicantDialog } from "../../../app/slices/Job/jobsSlice";

export const ModalApplicant = () => {
  const dispatch = useDispatch();
  const {
    jobApplicant: { dialog },
  } = useSelector((state) => state.jobs);
  return (
    <Box className="card-data-grid">
      <GeneralModal
        open={dialog}
        title="Applicants"
        handleClose={() => dispatch(setJobApplicantDialog())}
      >
        <Box>
          <TabelApplicant />
        </Box>
      </GeneralModal>
    </Box>
  );
};
