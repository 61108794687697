import paramsFactory from "../../../utils/paramsFactory";
import api from "../../apiConfig";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/admin/job-field?${p}`);
  },
  create(data) {
    return api.post(`/api/admin/job-field`, data);
  },
  update(id, data) {
    return api.put(`/api/admin/job-field/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/admin/job-field/${id}`);
  },
};
