import React from "react";
import { Box, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import img_experience from "../../assets/images/icons/experience.png";
import WorkIcon from "@mui/icons-material/Work";

export const CardExperience = (props) => {
  return (
    <Card
      sx={{
        display: "flex",
        boxShadow: "1px 4px 10px rgba(0,0,0,.15)",
        justifyContent: "space-between",
        borderRadius: 3,
        my: 2,
        mr: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            component="div"
            variant="h6"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <WorkIcon color={"primary"} />
            &nbsp;
            {props.jobTitle}
          </Typography>
          <Typography
            component="div"
            variant="subtitle1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <ApartmentIcon color={"primary"} />
            &nbsp;
            {props.companyName}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ display: "flex", alignItems: "center", mt: 1 }}
          >
            <CalendarMonthIcon />
            &nbsp; Start: &nbsp;
            {props?.startDate?.split("-")[0] +
              " - " +
              props?.startDate?.split("-")[1]}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ display: "flex", alignItems: "center", mt: 1 }}
          >
            <CalendarMonthIcon />
            &nbsp; End: &nbsp;
            {props?.endDate?.split("-")[0] +
              " - " +
              props?.endDate?.split("-")[1]}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ display: "flex", alignItems: "center", mt: 1 }}
          >
            {props?.duties}
          </Typography>
        </CardContent>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#ecf0f1",
          width: 160,
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: 80 }}
          image={img_experience}
          alt="Experience"
        />
      </Box>
    </Card>
  );
};
