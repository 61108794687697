import React, { useEffect, useMemo, useState } from "react";
import { Box, Avatar } from "@mui/material";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import ModalInfo from "./Modal/ModalInfo";
import { Header } from "../DataGridTable/Header";
import AlertDialog from "../AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  CVTemplates,
  resetFilters,
  setDialog,
  setFilters,
} from "../../app/slices/cvTemplatesSlice";
import { DataGridTable } from "../DataGridTable/DataGridTable";

export const Table = () => {
  const dispatch = useDispatch();
  const {
    cvTemplates: { data, filters, loading, total },
    manageCVTemplate: { dialog },
  } = useSelector((state) => state.cvTemplates);

  const fetchData = () => {
    dispatch(CVTemplates.index());
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        headerClassName: "employer-grid--header",
        sortable: false,
        flex: 1,
        minWidth: 200,
        renderCell: (params) => {
          return (
            <Box
              display={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <a href={params.row.thumbnail_path} target="_blank">
                <Avatar
                  sx={{
                    width: 60,
                    maxHeight: 60,
                    borderRadius: 1,
                    border: "2px solid #e74c3c",
                  }}
                  alt="Remy Sharp"
                  src={params.row?.thumbnail_path}
                />
              </a>
              &nbsp; &nbsp;
              <ColumnText
                text={params.row?.name}
                variant="subtitle1"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "path",
        headerName: "Path",
        headerClassName: "employer-grid--header",
        flex: 1,
        minWidth: 50,
        sortable: false,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnText
                text={params.row?.file_path}
                variant="subtitle2"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "operations",
        headerName: "Operations",
        flex: 1,
        minWidth: 120,
        headerClassName: "employer-grid--header",
        sortable: false,
        renderCell: (params) => {
          const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useState(false);
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnButton
                onClick={() =>
                  dispatch(
                    setDialog({
                      id: params.row.id,
                      name: params.row.name,
                      file_path: params.row.file_path,
                      thumbnail_path: params.row.thumbnail_path,
                    })
                  )
                }
                type={"edit"}
              />
              <ColumnButton
                onClick={() => setShowConfirmDeleteModal(true)}
                type={"delete"}
              />
              <AlertDialog
                open={showConfirmDeleteModal}
                action={() =>
                  dispatch(CVTemplates.deleteCVTemplate(params.row.id))
                }
                handleClose={() => setShowConfirmDeleteModal(false)}
                title="Delete CV template"
                message={`Are you sure you want to delete ${params.row?.name}`}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <Box sx={{}}>
      <Header
        title="Cv Templates"
        btnTitle={"Add Template"}
        isSearchable={false}
        handleAdd={() => dispatch(setDialog())}
      />
      <Box>
        <DataGridTable
          rowCount={total}
          rows={data}
          loading={loading}
          page={filters.page}
          columns={columns}
          pageSize={filters.take}
          onPageChange={(newPage) => {
            dispatch(
              setFilters({
                skip: newPage * filters.take,
                page: newPage,
              })
            );
          }}
          onPageSizeChange={(newPageSize) => {
            dispatch(
              setFilters({
                take: newPageSize,
              })
            );
            if (filters.skip === 0) {
              dispatch(CVTemplates.index());
            } else {
              dispatch(
                setFilters({
                  skip: 0,
                  page: 0,
                })
              );
            }
          }}
        />
      </Box>
      {dialog && <ModalInfo />}
    </Box>
  );
};
