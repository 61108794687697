import { Box, Typography } from '@mui/material'
import Lottie from 'lottie-web'
import React from 'react'
import { useEffect } from 'react'
import anim_noData from '../../assets/animations/anim_no-data.json'
export const NoData = () => {

    useEffect(() => {
        Lottie.loadAnimation({
            container: document.querySelector('#no-data-anim'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: anim_noData
        })
    }, [])
    return (
        <Box sx={{
            my:15
        }}>
            <Box sx={{
                width: 160,
                height: 160,
                overflow: 'hidden',
                mx: 'auto',
                my: 0,
                display: 'flex',
                justifyContent: 'center',
            }}>
                <span id="no-data-anim"></span>
            </Box>
            <Typography variant='h6' sx={{ textAlign: 'center' }}>
                No Rows
            </Typography>
        </Box>
    )
}
