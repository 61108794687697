import paramsFactory from "../../../utils/paramsFactory";
import api from "../../apiConfig";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const _params = {
      ...params,
      province_id: params.province_id?.id || null,
      city_id: params.city_id?.id || null,
    };
    const p = paramsFactory(_params);
    return api.get(`/api/admin/seeker?${p}`);
  },
  show(id) {
    return api.get(`/api/admin/seeker/${id}`);
  },
  delete(id) {
    return api.delete(`/api/admin/seeker/${id}`);
  },
};
