import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login(data) {
    return api.post(`/api/auth/admin/login`, data);
  },
  logout() {
    return api.get(`/api/auth/logout`);
  },
  changePassword(data) {
    return api.post(`/api/auth/change-password`, data);
  },
};
