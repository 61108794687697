import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { ChangeStatusByChips } from "./ChangeStatusByChips";
import ModalInfo from "../JobTitle/Modal/ModalInfo";
import Filter from "./Filters";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminNotes,
  deleteNoteFromStore,
  resetFilters,
  setFilters,
  setFiltersDialog,
} from "../../app/slices/adminNotesSlice";
import { useDebounce } from "use-debounce";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { setDialog } from "../../app/slices/Job/jobTitleSlice";
import dayjs from "dayjs";
import useMutate from "../../hooks/useMutate";
import { showNotification } from "../../app/slices/notificationSlice";
import AlertDialog from "../AlertDialog";
import { setPendingStats } from "../../app/slices/appSlice";

export const Tabel = () => {
  const dispatch = useDispatch();
  const {
    adminNotes: { data, loading, filters, total },
  } = useSelector((state) => state.adminNotes);
  const {
    manageJobTitle: { dialog },
  } = useSelector((state) => state.jobTitle);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);

  const [noteId, setNoteId] = useState(null);
  const [noteStatus, setNoteStatus] = useState(null);

  const changeNoteStatus = (id, status) => {
    let infoData = {
      status: status,
    };

    const currentNote = data.find((note) => note.id === id);
    setNoteStatus(currentNote.status);

    if (currentNote) {
      if (currentNote.status === "pending" && status !== "pending") {
        dispatch(
          setPendingStats({
            admin_notes: pending_admin_notes - 1,
            pending_employers,
          })
        );
      } else if (currentNote.status !== "pending" && status === "pending") {
        dispatch(
          setPendingStats({
            admin_notes: pending_admin_notes + 1,
            pending_employers,
          })
        );
      }
    }

    dispatch(AdminNotes.updateStatus(id, infoData));
  };

  const { pending_admin_notes, pending_employers } = useSelector(
    (state) => state.app
  );

  const { mutate: deleteNote, isMutating: deletingNote } = useMutate(
    "/admin/admin-notes",
    {
      action: "delete",
      onSuccess: (res) => {
        dispatch(deleteNoteFromStore(res?.data?.data[0]));
        dispatch(
          showNotification({
            type: "success",
            message: "Note deleted successfully",
          })
        );
      },
      onError: (err) => {
        dispatch(
          showNotification({
            type: "error",
            message: err.response?.data?.errors[0],
          })
        );
      },
    }
  );

  const columns = [
    {
      field: "notes",
      headerName: "Notes",
      headerClassName: "employer-grid--header",
      sortable: false,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row.notes}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "employer-grid--header",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <ChangeStatusByChips
            id={params.row.id}
            status={params.row.status}
            onChangeStatus={(item) => {
              changeNoteStatus(params.row.id, item.id);
            }}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      headerClassName: "employer-grid--header",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText text={params.row.created_by_name} variant="subtitle1" />
            <ColumnText
              text={dayjs(params.row.created_at).format("YYYY-MM-DD HH:mm")}
              variant="subtitle2"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      sortable: false,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useState(false);
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => {
                dispatch(setDialog());
                setNoteId(params.row.id);
                setNoteStatus(params.row.status);
              }}
              type={"add"}
            />
            <ColumnButton
              onClick={() => setShowConfirmDeleteModal(true)}
              type={"delete"}
            />
            <AlertDialog
              open={showConfirmDeleteModal}
              action={() => {
                deleteNote({}, { pathParam: params.row.id });
                params.row.status === "pending" &&
                  dispatch(
                    setPendingStats({
                      admin_notes: pending_admin_notes - 1,
                      pending_employers,
                    })
                  );
              }}
              handleClose={() => setShowConfirmDeleteModal(false)}
              title="Delete Note"
              message={`Are you sure you want to delete this note`}
              loading={deletingNote}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(AdminNotes.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTable
          value={search}
          title="Admin Notes"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          fetchData={fetchData}
          isFilter={true}
          handleFilter={() => {
            dispatch(setFiltersDialog());
          }}
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Box mt={2}>
          <DataGridTable
            rowCount={total}
            rows={data}
            loading={loading}
            page={filters.page}
            columns={columns}
            pageSize={filters.take}
            onPageChange={(newPage) => {
              dispatch(
                setFilters({
                  skip: newPage * filters.take,
                  page: newPage,
                })
              );
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(
                setFilters({
                  take: newPageSize,
                })
              );
              if (filters.skip === 0) {
                dispatch(AdminNotes.index());
              } else {
                dispatch(
                  setFilters({
                    skip: 0,
                    page: 0,
                  })
                );
              }
            }}
          />
        </Box>
        {/* Modal */}
        {dialog && <ModalInfo noteId={noteId} noteStatus={noteStatus} />}
        {/* Filter */}
        <Filter fetchData={fetchData} />
      </Box>
    </>
  );
};

export default Tabel;
