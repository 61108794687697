import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import SelectProvince from "../Selects/SelectProvince";
import { SelectCity } from "../Selects/SelectCity";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  Seeker,
  resetFilters,
  setFilters,
  setFiltersDialog,
} from "../../app/slices/Users/seekerSlice";
import SelectGender from "../Selects/SelectGender";
import { SelectYesNo } from "../Selects/SelectYesNo";

export default function Filter({ fetchData, ...props }) {
  const {
    seeker: { filters, dialog },
  } = useSelector((state) => state.seeker);
  const dispatch = useDispatch();

  const handleSearch = () => {
    fetchData();
  };

  const handleRemoveSearch = () => {
    dispatch(resetFilters());
    dispatch(Seeker.index());
  };

  const toggleDrawer = () => (event) => {
    dispatch(setFiltersDialog());
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        mt: 8,
      }}
      role="presentation"
    >
      <Grid container rowSpacing={1} sx={{ px: 1, pt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Filter Advanced</Typography>
        </Grid>

        <Grid item xs={12}>
          <SelectGender
            value={filters.gender}
            onChange={(e, newValue) => {
              dispatch(setFilters({ gender: newValue }));
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectProvince
            value={filters.province_id}
            onChange={(e, newValue) => {
              dispatch(
                setFilters({
                  province_id: newValue,
                  city_id: null,
                })
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectCity
            province_id={filters.province_id?.id}
            value={filters.city_id}
            disabled={!filters.province_id}
            onChange={(e, newValue) => {
              dispatch(setFilters({ city_id: newValue }));
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectYesNo
            label="Has Driver License"
            value={
              filters.has_driver_license === null
                ? null
                : filters.has_driver_license === true
                ? { id: "true", label: "Yes" }
                : { id: "false", label: "No" }
            }
            onChange={(e, newValue) => {
              dispatch(
                setFilters({
                  has_driver_license: newValue ? newValue.id === "true" : null,
                })
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectYesNo
            label="Has Uploaded CV"
            value={
              filters.has_cv === null
                ? null
                : filters.has_cv === true
                ? { id: "true", label: "Yes" }
                : { id: "false", label: "No" }
            }
            onChange={(e, newValue) => {
              dispatch(
                setFilters({
                  has_cv: newValue ? newValue.id === "true" : null,
                })
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => handleSearch()}
            sx={{ width: "100%", mt: 5 }}
            variant="outlined"
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => handleRemoveSearch()}
            color="error"
            sx={{ width: "100%", mt: 1 }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"} className="mt-5 pt-5">
        <Drawer anchor={"right"} open={dialog} onClose={toggleDrawer()}>
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
