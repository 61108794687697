import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import SelectProvince from "../Selects/SelectProvince";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import SelectExpired from "../Selects/SelectExpired";
import { SelectJobTitle } from "../Selects/SelectJobTitle";
import { SelectJobField } from "../Selects/SelectJobField";
import { SelectCompany } from "../Selects/SelectCompany";
import { useDispatch, useSelector } from "react-redux";
import {
  Jobs,
  resetFilters,
  setFilters,
  setFiltersDialog,
} from "../../app/slices/Job/jobsSlice";

export default function Filter({ fetchData, ...props }) {
  const {
    jobs: { filters, dialog },
  } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();

  const handleSearch = () => {
    fetchData();
  };

  const handleRemoveSearch = () => {
    dispatch(resetFilters());
    dispatch(Jobs.index());
  };

  const toggleDrawer = () => (event) => {
    dispatch(setFiltersDialog());
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        mt: 8,
      }}
      role="presentation"
    >
      <Grid container rowSpacing={1} sx={{ px: 1, pt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Filter Advancis</Typography>
        </Grid>
        <Grid item xs={12}>
          <SelectCompany
            value={filters.company_id}
            onChange={(e, newValue) => {
              dispatch(setFilters({ company_id: newValue }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectExpired
            value={filters.expired}
            onChange={(e, newValue) => {
              dispatch(setFilters({ expired: newValue }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectProvince
            value={filters.province_id}
            onChange={(e, newValue) => {
              dispatch(setFilters({ province_id: newValue }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectJobField
            value={filters.job_field_id}
            onChange={(e, newValue) => {
              dispatch(setFilters({ job_field_id: newValue }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectJobTitle
            value={filters.job_title_id}
            onChange={(e, newValue) => {
              dispatch(setFilters({ job_title_id: newValue }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => handleSearch()}
            sx={{ width: "100%" }}
            variant="outlined"
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => handleRemoveSearch()}
            color="error"
            sx={{ width: "100%", mt: 1 }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"} className="mt-5 pt-5">
        <Drawer
          anchor={"right"}
          open={dialog}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
