import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import ModalInfo from "./Modal/ModalInfo";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import { Header } from "../DataGridTable/Header";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import {
  ApplicantStage,
  resetFilters,
  setDialog,
  setFilters,
} from "../../app/slices/Job/applicantStageSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../AlertDialog";
const Table = () => {
  const dispatch = useDispatch();
  const {
    applicantStage: { data, filters, loading, total },
    manageApplicationStage: { dialog },
  } = useSelector((state) => state.applicantStage);

  const columns = useMemo(
    () => [
      {
        field: "name_en",
        headerName: "Name EN",
        headerClassName: "employer-grid--header",
        flex: 1,
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Box>
              <ColumnText
                text={params.row?.name_en}
                variant="subtitle1"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "name_ar",
        headerName: "Name AR",
        headerClassName: "employer-grid--header",
        sortable: false,
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnText
                text={params.row?.name_ar}
                variant="subtitle2"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "operations",
        headerName: "Operations",
        flex: 1,
        headerClassName: "employer-grid--header",
        sortable: false,
        minWidth: 100,
        renderCell: (params) => {
          const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useState(false);
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnButton
                onClick={() =>
                  dispatch(
                    setDialog({
                      id: params.row.id,
                      name_en: params.row.name_en,
                      name_ar: params.row.name_ar,
                    })
                  )
                }
                type={"edit"}
              />
              <ColumnButton
                onClick={() => setShowConfirmDeleteModal(true)}
                type={"delete"}
              />
              <AlertDialog
                open={showConfirmDeleteModal}
                action={() =>
                  dispatch(ApplicantStage.deleteApplicantStage(params.row.id))
                }
                handleClose={() => setShowConfirmDeleteModal(false)}
                title="Delete applicant stage"
                message={`Are you sure you want to delete (${params.row?.name_en} - ${params.row?.name_ar})`}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  const fetchData = () => {
    dispatch(ApplicantStage.index());
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <Box sx={{}}>
      <Header
        title="Applicant Stages"
        btnTitle={"Add Stage"}
        isSearchable={false}
        handleAdd={() => dispatch(setDialog())}
      />
      <Box>
        <DataGridTable
          rowCount={total}
          rows={data}
          loading={loading}
          page={filters.page}
          columns={columns}
          pageSize={filters.take}
          onPageChange={(newPage) => {
            dispatch(
              setFilters({
                skip: newPage * filters.take,
                page: newPage,
              })
            );
          }}
          onPageSizeChange={(newPageSize) => {
            dispatch(
              setFilters({
                take: newPageSize,
              })
            );
            if (filters.skip === 0) {
              dispatch(ApplicantStage.index());
            } else {
              dispatch(
                setFilters({
                  skip: 0,
                  page: 0,
                })
              );
            }
          }}
        />
      </Box>
      {dialog && <ModalInfo />}
    </Box>
  );
};

export default Table;
