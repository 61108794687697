import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import { dataClickItem, methodRequestStatus } from "../../recoil/global";
import AlertDialog from "../AlertDialog";
import {
  Cities,
  resetFilters,
  setDialog,
  setFilters,
  setFiltersDialog,
} from "../../app/slices/citiesSlicee";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import Filter from "./Filters";

export const Tabel = () => {
  const dispatch = useDispatch();
  const {
    cities: { data, filters, loading, total },
    manageCities: { dialog },
  } = useSelector((state) => state.cities);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);

  const columns = [
    {
      field: "name_en",
      headerName: "Name EN",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row?.name_en}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "name_ar",
      headerName: "Name AR",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.name_ar}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "Province",
      headerName: "Province",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.province?.name_en}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      headerClassName: "employer-grid--header",
      sortable: false,
      minWidth: 120,
      renderCell: (params) => {
        const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useState(false);
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() =>
                dispatch(
                  setDialog({
                    id: params.row.id,
                    name_en: params.row.name_en,
                    name_ar: params.row.name_ar,
                    province_id: params.row.province,
                  })
                )
              }
              type={"edit"}
            />
            <ColumnButton
              onClick={() => setShowConfirmDeleteModal(true)}
              type={"delete"}
            />
            <AlertDialog
              open={showConfirmDeleteModal}
              action={() => dispatch(Cities.deleteCity(params.row.id))}
              handleClose={() => setShowConfirmDeleteModal(false)}
              title="Delete City"
              message={`Are you sure you want to delete (${params.row?.name_en} - ${params.row?.name_ar})`}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(Cities.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <>
      <Box m={2}>
        <HeaderGridTable
          title="Cities"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          btn={"Add City"}
          fetchData={fetchData}
          isFilter={true}
          handleFilter={() => {
            dispatch(setFiltersDialog());
          }}
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
          handleAdd={() => dispatch(setDialog())}
        />
        <Box mt={2}>
          <DataGridTable
            rowCount={total}
            rows={data}
            loading={loading}
            page={filters.page}
            columns={columns}
            pageSize={filters.take}
            onPageChange={(newPage) => {
              dispatch(
                setFilters({
                  skip: newPage * filters.take,
                  page: newPage,
                })
              );
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(
                setFilters({
                  take: newPageSize,
                })
              );
              if (filters.skip === 0) {
                dispatch(Cities.index());
              } else {
                dispatch(
                  setFilters({
                    skip: 0,
                    page: 0,
                  })
                );
              }
            }}
          />
        </Box>
        {/* Modal */}
        {dialog && <ModalInfo />}
        <Filter fetchData={fetchData} />
      </Box>
    </>
  );
};

export default Tabel;
