import React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import GeneralModal from "../../GeneralModal/GeneralModal";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import { useDispatch, useSelector } from "react-redux";
import { setDialog, User } from "../../../app/slices/Users/userSlice";
export default function ModalInfo() {
  const dispatch = useDispatch();
  const {
    manageUser: { dialog, form, loading },
  } = useSelector((state) => state.user);
  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const infoData = {
      name: data.get("name"),
      email: data.get("email"),
      password: data.get("password"),
      mobile: data.get("mobile"),
    };

    // function api request
    if (form.id) {
      dispatch(User.update(form.id, infoData));
    } else {
      dispatch(User.create(infoData));
    }
  };
  // close modal
  const handleClose = () => {
    dispatch(setDialog());
  };
  return (
    <GeneralModal
      maxWidth="md"
      open={dialog}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      loading={loading}
      type={form?.id ? "edit" : "add"}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Box sx={{ mx: 2, mt: 1, display: "flex", alignItems: "center" }}>
          <ContactEmergencyIcon color={"primary"} />
          <Typography variant="h5" fontSize={18} fontWeight={500}>
            &nbsp; Add Admin
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                name="name"
                required
                label="Name"
                type="text"
                id="name"
                defaultValue={form?.name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                name="email"
                label="Email"
                type="text"
                id="email"
                defaultValue={form?.email}
              />
            </Box>
          </Grid>
          {!form.id && (
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  mx: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  required
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                key={form?.mobile}
                size="small"
                margin="normal"
                fullWidth
                required
                name="mobile"
                label="Mobile"
                type="phone"
                id="mobile"
                defaultValue={form?.mobile}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
