import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
const employerApi = factory.get("employer");
const initialState = {
  employer: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: "",
      status: "",
      province_id: null,
    },
    dialog: false,
  },
  manageEmployer: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      email: "",
      mobile: "",
      name: "",
      password: "",
      company_name: "",
      website: "",
      full_address: "",
      contact_email: "",
      facebook: "",
      linkedin: "",
      employer_field_id: null,
      city_id: null,
      profile_pic: null,
      province_id: null,
    },
  },
};

export const employerSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setEmployer: (state, action) => {
      state.employer.data = action.payload.data;
      state.employer.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.employer.filters = {
        ...state.employer.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.employer.filters = initialState.employer.filters;
    },
    setFiltersDialog: (state, action) => {
      state.employer.dialog = !state.employer.dialog;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manageEmployer.form = payload;
      } else {
        state.manageEmployer.form = initialState.manageEmployer.form;
      }
      state.manageEmployer.dialog = !state.manageEmployer.dialog;
    },
    resetForm: (state, action) => {
      state.manageEmployer.form = initialState.manageEmployer.form;
    },
  },
});

export const {
  setLoading,
  setEmployer,
  setFilters,
  resetFilters,
  setFiltersDialog,
  setDialog,
  resetForm,
} = employerSlice.actions;

export default employerSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().employer.employer.filters;
    const _filters = {
      ...filters,
      province_id: filters.province_id?.id || null,
    };
    dispatch(setLoading("employer"));
    const res = await employerApi.index(_filters);
    dispatch(setEmployer(res.data));
    dispatch(setLoading("employer"));
  } catch (err) {
    dispatch(setLoading("employer"));
    console.log(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageEmployer"));
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "profile_pic") {
        if (data.profile_pic) {
          formData.append(`file`, data.profile_pic);
        }
      } else if (key === "city_id" || key === "employer_field_id") {
        formData.append(key, data[key].id);
      } else {
        key !== "province_id" && formData.append(key, data[key]);
      }
    });
    await employerApi.create(formData);
    dispatch(setLoading("manageEmployer"));
    dispatch(
      showNotification({
        type: "success",
        message: "Employer Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageEmployer"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const update = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageEmployer"));
    const formData = new FormData();
    console.log(data);
    Object.keys(data).forEach((key) => {
      if (key === "profile_pic") {
        if (data.profile_pic) {
          formData.append(`profile_pic`, data.profile_pic);
        }
      } else if (key === "city_id" || key === "employer_field_id") {
        formData.append(key, data[key].id);
      } else {
        key !== "province_id" && formData.append(key, data[key]);
      }
    });
    // formData.append("_method", "PUT");
    await employerApi.update(id, formData);
    dispatch(setLoading("manageEmployer"));
    dispatch(
      showNotification({
        type: "success",
        message: "Employer updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageEmployer"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const updateStatus = (id, status) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("employer"));
    await employerApi.updateStatus(id, status);
    dispatch(setLoading("employer"));
    dispatch(
      showNotification({
        type: "success",
        message: "Status updated successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("employer"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteEmployer = (id) => async (dispatch, getState) => {
  try {
    await employerApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Employer deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};
export const Employer = {
  index,
  create,
  update,
  updateStatus,
  deleteEmployer,
};
