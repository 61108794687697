import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import Tabel from "../components/Employer/Tabel";

export const Employer = () => {
  return (
    <>
      <Slider />
      <Tabel />
    </>
  );
};
