import { deleteData, getData, postData, putData } from "..";

// get Jobs
export const GetJobsData = async (skip, take, search, expired, province_id, job_field_id, job_title_id, company_id) => {
    try {
        const request = await getData(`/admin/jobs?skip=${skip}&take=${take}&search=${search}&expired=${expired}&province_id=${province_id}&job_field_id=${job_field_id}&job_title_id=${job_title_id}&company_id=${company_id}`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
    }
};

// get Jobs By Id
export const GetJobsByIdData = async (id) => {
    if (id) {
        try {
            const request = await getData(`/admin/jobs/${id}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// get Job applicant By Id
export const GetJobApplicantData = async (id, skip, take, search) => {
    try {
        const request = await getData(`/admin/job-applicant?job_id=${id}&skip=${skip}&take=${take}&search=${search}`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};
// post Jobs
export const PostJobs = async (data) => {
    try {
        const request = await postData(`/admin/jobs`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// post Jobs
export const PutJobs = async (id, data) => {
    try {
        const request = await putData(`/admin/jobs/${id}`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};


// delete Jobs
export const DeleteJobs = async (id) => {
    try {
        const request = await deleteData(`/admin/jobs/${id}`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};


// change passwoed employer
export const PostChangePasswordEmployer = async (id, data) => {
    try {
        const request = await postData(`/admin/user/change_password/${id}`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};


// change accepted or rejected employer
export const PostChangeStatusEmployer = async (data) => {
    try {
        const request = await postData(`/admin/employer/reject-accept`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};
