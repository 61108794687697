import React from "react";
import ForgotPasswordForm from "../components/ForgotPassword/ForgotPasswordForm";
import { Box } from "@mui/material";

const ForgotPassword = () => {
  return (
    <Box
      sx={{
        marginLeft: "-260px",
      }}
    >
      <ForgotPasswordForm />
    </Box>
  );
};

export default ForgotPassword;
