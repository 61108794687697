import React from 'react';
import LoginForm from '../components/Auth/LoginForm';
import { Box } from '@mui/material';

const Login = () => {
    return (
        <Box sx={{
            marginLeft: '-260px'
        }}>
            <LoginForm />
        </Box>
    )
}

export default Login;