import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import { Box } from "@mui/material";
import Tabel from "../components/ApplecantStage/Tabel";

const ApplecantStages = () => {
  return (
    <>
      <Slider />
      <Box sx={{ my: 5, px: 4 }}>
        <Tabel />
      </Box>
    </>
  );
};

export default ApplecantStages;
