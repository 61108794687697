import Slider from "../components/Slidbar/Slidbar";
import Table from "../components/Jobs/Table";

export const Jobs = () => {
  return (
    <>
      <Slider />
      <Table />
    </>
  );
};

export default Jobs;
