import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const feedbackApi = factory.get("feedback");
const initialState = {
  feedback: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: "",
      status: "",
    },
    dialog: false,
  },
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setFeedback: (state, action) => {
      state.feedback.data = action.payload.data;
      state.feedback.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.feedback.filters = {
        ...state.feedback.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.feedback.filters = initialState.feedback.filters;
    },
  },
});

export const { setLoading, setFeedback, setFilters, resetFilters } =
  feedbackSlice.actions;

export default feedbackSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().feedback.feedback.filters;
    dispatch(setLoading("feedback"));
    const res = await feedbackApi.index(filters);
    dispatch(setFeedback(res.data));
    dispatch(setLoading("feedback"));
  } catch (err) {
    dispatch(setLoading("feedback"));
    console.log(err);
  }
};

const deleteFeedback = (id) => async (dispatch, getState) => {
  try {
    await feedbackApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Feedback deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const Feedback = {
  index,
  deleteFeedback,
};
