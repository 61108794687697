import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileSeeker } from "./ProfileSeeker";
import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Seeker } from "../../app/slices/Users/seekerSlice";

export const ModalProfileSeeker = ({ handleClose, ...props }) => {
  const dispatch = useDispatch();
  const {
    seekerProfile: { dialog, loading, profile },
  } = useSelector((state) => state.seeker);

  useEffect(() => {
    dispatch(Seeker.showById(profile.id));
  }, [dispatch, profile.id]);

  return (
    <Dialog
      open={dialog}
      onClose={handleClose}
      maxWidth={"lg"}
      sx={{
        width: "100%",
      }}
    >
      <AppBar sx={{ width: "100%", position: "relative" }} color={"primary"}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Profile Seeker
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: "100%", height: "70vh" }}>
        {loading ? (
          <Box>
            <CircularProgress
              size={30}
              sx={{
                marginLeft: "50%",
                marginTop: "15%",
              }}
            />
          </Box>
        ) : (
          <ProfileSeeker data={profile} handleClose={handleClose} />
        )}
      </Box>
    </Dialog>
  );
};
