import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import GeneralModal from "../../GeneralModal/GeneralModal";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Auth, setChangePasswordDialog } from "../../../app/slices/authSlice";

const ModalChangePassword = () => {
  const dispatch = useDispatch();
  const {
    changePassword: { dialog, loading },
  } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const togglePassword = () => setShowPassword(!showPassword);
  const toggleConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleClose = () => {
    dispatch(setChangePasswordDialog());
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const infoData = {
      password: data.get("password"),
    };
    const newPassword = data.get("new_password");
    const confirmPassword = data.get("password");
    if (newPassword === confirmPassword) {
      dispatch(Auth.changePassword(infoData));
    } else {
      setPasswordError(true);
    }
  };
  return (
    <GeneralModal
      maxWidth="md"
      open={dialog}
      onSubmit={handleSubmit}
      changePassword={true}
      loading={loading}
      handleClose={handleClose}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                error={passwordError}
                name="new_password"
                label="New password"
                type={showPassword ? "text" : "password"}
                id="new_password"
                autoComplete="current-password"
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                error={passwordError}
                name="password"
                label="Confirm password"
                type={showConfirmPassword ? "text" : "password"}
                id="password"
                autoComplete="confirmPassword"
                helperText={passwordError && "Passwords do not match"}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
};

export default ModalChangePassword;
