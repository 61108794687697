import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
const jobTitleApi = factory.get("jobTitle");
const initialState = {
  jobTitle: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: "",
    },
    dialog: false,
  },
  manageJobTitle: {
    form: {
      id: "",
      name_en: "",
      name_ar: "",
      job_field_id: null,
    },
    dialog: false,
    loading: false,
  },
};

export const jobTitleSlice = createSlice({
  name: "jobTitle",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setJobTitle: (state, action) => {
      state.jobTitle.data = action.payload.data;
      state.jobTitle.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.jobTitle.filters = {
        ...state.jobTitle.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.jobTitle.filters = initialState.jobTitle.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manageJobTitle.form.id = payload.id;
        state.manageJobTitle.form.name_en = payload.name_en;
        state.manageJobTitle.form.name_ar = payload.name_ar;
        state.manageJobTitle.form.job_field_id = payload.job_field_id;
      } else {
        state.manageJobTitle.form = initialState.manageJobTitle.form;
      }
      state.manageJobTitle.dialog = !state.manageJobTitle.dialog;
    },
    resetForm: (state, action) => {
      state.manageJobTitle.form = initialState.manageJobTitle.form;
    },
  },
});

export const {
  setLoading,
  setJobTitle,
  setFilters,
  resetFilters,
  resetForm,
  setDialog,
} = jobTitleSlice.actions;

export default jobTitleSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().jobTitle.jobTitle.filters;
    dispatch(setLoading("jobTitle"));
    const res = await jobTitleApi.index(filters);
    dispatch(setJobTitle(res.data));
    dispatch(setLoading("jobTitle"));
  } catch (err) {
    dispatch(setLoading("jobTitle"));
    console.log(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageJobTitle"));
    const infoData = {
      name_en: data.name_en,
      name_ar: data.name_ar,
      job_field_id: data.job_field_id.id,
    };
    await jobTitleApi.create(infoData);
    dispatch(setLoading("manageJobTitle"));
    dispatch(
      showNotification({
        type: "success",
        message: "Job title Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageApplicationStage"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const update = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageJobTitle"));
    const infoData = {
      name_en: data.name_en,
      name_ar: data.name_ar,
      job_field_id: data.job_field_id.id,
    };
    await jobTitleApi.update(id, infoData);
    dispatch(setLoading("manageJobTitle"));
    dispatch(
      showNotification({
        type: "success",
        message: "Job title updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageApplicationStage"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteJobTitle = (id) => async (dispatch, getState) => {
  try {
    await jobTitleApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Job Title deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const JobTitle = {
  index,
  create,
  update,
  deleteJobTitle,
};
