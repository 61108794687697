import { useEffect, useState } from "react";
import { Box, Chip } from "@mui/material";
import ModalInfo from "./Modal/ModalInfo";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ModalDeactivate from "./Modal/ModalDeactivate";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import {
  resetFilters,
  setDialog,
  setFilters,
  User,
} from "../../app/slices/Users/userSlice";
import AlertDialog from "../AlertDialog";
export const Table = () => {
  const dispatch = useDispatch();
  const {
    user: { data, filters, loading, total },
    manageUser: { dialog },
  } = useSelector((state) => state.user);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      sortable: false,
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.name}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.email}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "employer-grid--header",
      minWidth: 120,
      renderCell: (params) => {
        return (
          <ColumnText
            text={params.row?.mobile ? params.row?.mobile : "N/A"}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "is_active",
      headerName: "Status",
      flex: 1,
      minWidth: 70,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Chip
            sx={{
              width: "45%",
            }}
            color={params.row.is_active ? "success" : "error"}
            label={params.row.is_active ? "Active" : "Inactive"}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      flex: 1,
      minWidth: 170,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.created_at
                  ? new Date(params.row?.created_at).toLocaleString()
                  : "N/A"
              }
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      minWidth: 80,
      sortable: false,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useState(false);
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() =>
                dispatch(
                  setDialog({
                    id: params.row.id,
                    name: params.row.name,
                    email: params.row.email,
                    mobile: params.row.mobile,
                  })
                )
              }
              type={"edit"}
            />
            <ColumnButton
              onClick={() => setShowConfirmDeleteModal(true)}
              type={"activate"}
              is_active={params.row.is_active}
            />
            <AlertDialog
              open={showConfirmDeleteModal}
              action={() => dispatch(User.deleteUser(params.row.id))}
              handleClose={() => setShowConfirmDeleteModal(false)}
              title={params.row.is_active ? `Deactivate user` : `Activate user`}
              message={
                params.row.is_active
                  ? `Are you sure you want to deactivate ${params.row.name}`
                  : `Are you sure you want to activate ${params.row.name}`
              }
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(User.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(User.index());
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTable
          title="Admins"
          icon={<SupervisorAccountIcon size={25} color={"primary"} />}
          fetchData={fetchData}
          btn={"Add Admin"}
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
          handleAdd={() => dispatch(setDialog())}
        />
        <Box mt={2}>
          <DataGridTable
            rowCount={total}
            rows={data}
            loading={loading}
            page={filters.page}
            columns={columns}
            pageSize={filters.take}
            onPageChange={(newPage) => {
              dispatch(
                setFilters({
                  skip: newPage * filters.take,
                  page: newPage,
                })
              );
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(
                setFilters({
                  take: newPageSize,
                })
              );
              if (filters.skip === 0) {
                dispatch(User.index());
              } else {
                dispatch(
                  setFilters({
                    skip: 0,
                    page: 0,
                  })
                );
              }
            }}
          />
        </Box>
        {/* Modal */}
        {dialog && <ModalInfo />}
        <ModalDeactivate />
      </Box>
    </>
  );
};

export default Table;
