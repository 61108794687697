import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const bannerApi = factory.get("banner");
const initialState = {
  banner: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: "",
    },
    dialog: false,
  },
  manageBanner: {
    form: {
      id: "",
      title: "",
      url: "",
      attachment: null,
      description: "",
    },
    dialog: false,
    loading: false,
  },
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setBanner: (state, action) => {
      state.banner.data = action.payload.data;
      state.banner.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.banner.filters = {
        ...state.banner.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.banner.filters = initialState.banner.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manageBanner.form.id = payload.id;
        state.manageBanner.form.title = payload.title;
        state.manageBanner.form.url = payload.url;
        state.manageBanner.form.attachment = payload.attachment;
        state.manageBanner.form.description = payload.description;
      } else {
        state.manageBanner.form = initialState.manageBanner.form;
      }
      state.manageBanner.dialog = !state.manageBanner.dialog;
    },
    resetForm: (state, action) => {
      state.manageBanner.form = initialState.manageBanner.form;
    },
  },
});

export const {
  setLoading,
  setBanner,
  setFilters,
  resetFilters,
  resetForm,
  setDialog,
} = bannerSlice.actions;

export default bannerSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().banner.banner.filters;
    dispatch(setLoading("banner"));
    const res = await bannerApi.index(filters);
    dispatch(setBanner(res.data));
    dispatch(setLoading("banner"));
  } catch (err) {
    dispatch(setLoading("banner"));
    console.log(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageBanner"));
    await bannerApi.create(data);
    dispatch(setLoading("manageBanner"));
    dispatch(
      showNotification({
        type: "success",
        message: "Banner Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageBanner"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const update = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageBanner"));
    await bannerApi.update(id, data);
    dispatch(setLoading("manageBanner"));
    dispatch(
      showNotification({
        type: "success",
        message: "Banner updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageBanner"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteBanner = (id) => async (dispatch, getState) => {
  try {
    await bannerApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Banner deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const Banner = {
  index,
  create,
  update,
  deleteBanner,
};
