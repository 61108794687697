import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import Tabel from "../components/JobField/Tabel";

export const JobField = () => {
  
  return (
    <>
    <Slider />
    <Tabel />
    </>
  );
};
