import React from "react";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GradeIcon from "@mui/icons-material/Grade";
import SchoolIcon from "@mui/icons-material/School";

export const CardEducation = (props) => {
  return (
    <Card
      sx={{
        display: "flex",
        boxShadow: "1px 4px 10px rgba(0,0,0,.15)",
        borderRadius: 3,
        my: 2,
        width: "100%",
      }}
    >
      <CardContent width="100%">
        <Typography
          component="div"
          variant="h6"
          sx={{ display: "flex", alignItems: "center", width: "100%" }}
        >
          <SchoolIcon color={"primary"} />
          &nbsp;
          {props.name + " - " + props.field}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ display: "flex", alignItems: "center", mt: 1 }}
        >
          <SchoolIcon />
          &nbsp;
          {props.educationDegree}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ display: "flex", alignItems: "center", mt: 1 }}
        >
          <CalendarMonthIcon />
          &nbsp;
          {props.date?.split(" ")[0]}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ display: "flex", alignItems: "center", mt: 1 }}
        >
          <GradeIcon />
          &nbsp;
          {props.grade}
        </Typography>
      </CardContent>
    </Card>
  );
};
