import React, { forwardRef, useEffect, useState } from "react";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { factory } from "../../api/apiFactory";

const employerApi = factory.get("employer");

export const SelectCompany = forwardRef((props, ref) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchEmployer = (search = "") => {
    setLoading(true);
    employerApi.index({ skip: 0, take: 140, search: search }).then((res) => {
      setOptions(res.data.data.filter((item) => item.status === "accepted"));
      setLoading(false);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchEmployer(search);
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <Autocomplete
      ref={ref}
      options={options}
      {...props}
      getOptionLabel={(option) => `${option.company_name}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!props.label ? "Company" : props.label}
          error={props.error ? props.error : null}
          helperText={props.helperText ? props.helperText : null}
          onChange={(e, value) => {
            setSearch(e.target.value);
          }}
          required={props.required}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
});
