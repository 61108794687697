import { Box, Card, Typography, CardContent } from "@mui/material";
import React from "react";

const Skills = ({ skills = "" }) => {
  return (
    <Box>
      <Typography variant="h5" fontWeight={600}>
        Skills
      </Typography>
      <Card
        sx={{
          display: "flex",
          boxShadow: "1px 4px 10px rgba(0,0,0,.15)",
          justifyContent: "space-between",
          borderRadius: 3,
          my: 2,
          minHeight: "10em",
          mr: 2,
        }}
      >
        <CardContent>{skills}</CardContent>
      </Card>
    </Box>
  );
};

export default Skills;
