import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import {
  Feedback,
  resetFilters,
  setFilters,
} from "../../app/slices/feedbackSlice";
import { useDebounce } from "use-debounce";
import CloseIcon from "@mui/icons-material/Close";

export const Tabel = () => {
  const dispatch = useDispatch();
  const {
    feedback: { data, filters, loading, total },
  } = useSelector((state) => state.feedback);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.name}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "employer-grid--header",
      sortable: false,
      flex: 1,
      minWidth: 280,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.email}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: 0.5,
      minWidth: 60,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={`${params?.row?.rating} / 5`}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1.5,
      minWidth: 400,
      headerClassName: "employer-grid--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Box sx={{ maxWidth: "90%" }}>
              <ColumnText
                text={params?.row?.message}
                variant="subtitle1"
                fontWeight="bold"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              />
            </Box>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedMessage(params?.row);
              }}
              sx={{
                ml: 1,
                "&:hover": {
                  backgroundColor: "primary.lighter",
                  color: "primary.main",
                },
              }}
            >
              <MoreHorizIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(Feedback.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <>
      <Box m={2}>
        <HeaderGridTable
          title="Feedback"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          fetchData={fetchData}
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Box mt={2}>
          <DataGridTable
            rowCount={total}
            rows={data}
            loading={loading}
            page={filters.page}
            columns={columns}
            pageSize={filters.take}
            onPageChange={(newPage) => {
              dispatch(
                setFilters({
                  skip: newPage * filters.take,
                  page: newPage,
                })
              );
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(
                setFilters({
                  take: newPageSize,
                })
              );
              if (filters.skip === 0) {
                dispatch(Feedback.index());
              } else {
                dispatch(
                  setFilters({
                    skip: 0,
                    page: 0,
                  })
                );
              }
            }}
          />
        </Box>
      </Box>

      <Dialog
        open={Boolean(selectedMessage)}
        onClose={() => setSelectedMessage(null)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxHeight: "80vh",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1,
          }}
        >
          <Box>
            <Typography variant="h6" component="div">
              Feedback Details
            </Typography>
            {selectedMessage && (
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mt: 0.5 }}
              >
                From: {selectedMessage.name} ({selectedMessage.email})
              </Typography>
            )}
          </Box>
          <IconButton
            onClick={() => setSelectedMessage(null)}
            size="small"
            sx={{
              "&:hover": {
                backgroundColor: "error.lighter",
                color: "error.main",
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent sx={{ py: 3 }}>
          {selectedMessage && (
            <>
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Rating
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {selectedMessage.rating} / 5
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Message
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: "pre-wrap",
                    backgroundColor: "grey.50",
                    p: 2,
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: "grey.200",
                  }}
                >
                  {selectedMessage.message}
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            variant="contained"
            onClick={() => setSelectedMessage(null)}
            sx={{
              minWidth: 100,
              textTransform: "none",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Tabel;
