import { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import { useState } from "react";
import { ChangeStatusByChips } from "./ChangeStatusByChips";
import Filters from "./Filters";
import {
  Employer,
  resetFilters,
  setDialog,
  setFilters,
  setFiltersDialog,
} from "../../app/slices/Users/employerSlice";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../AlertDialog";
export const Tabel = () => {
  const dispatch = useDispatch();
  const {
    employer: { data, loading, filters, total },
    manageEmployer: { dialog },
  } = useSelector((state) => state.employer);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);
  // const [emailSearch, setEmailSearch] = useState(null);
  // const [phoneSearch, setPhoneSearch] = useState(null);
  //change employer status function
  const changeEmployerStatus = (params, status) => {
    let dataInfo = {
      user_id: params.id,
      rejection_note: "No reason",
      status: status.id,
    };
    dispatch(Employer.updateStatus(dataInfo));
  };

  const columns = [
    {
      field: "company_name",
      headerName: "Company Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 195,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Avatar src={params.row?.profile_pic} sizes={"small"} />
            <ColumnText
              text={params.row?.company_name}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 195,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row?.name}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 265,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.email}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      minWidth: 195,
      headerClassName: "employer-grid--header",
      sortable: false,
      renderCell: (params) => {
        var t = "";
        if (params.row?.city?.province?.name_en && params.row?.city?.name_en) {
          t = `${params.row?.city?.province?.name_en} - ${params.row?.city?.name_en}`;
        } else if (
          params.row?.city?.province?.name_en &&
          !params.row?.city?.name_en
        ) {
          t = params.row?.city?.province?.name_en;
        } else if (
          !params.row?.city?.province?.name_en &&
          params.row?.city?.name_en
        ) {
          t = params.row?.city?.name_en;
        } else {
          t = "N/A";
        }
        return <ColumnText text={t} variant="subtitle2" fontWeight="bold" />;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      headerClassName: "employer-grid--header",

      renderCell: (params) => {
        return (
          <ChangeStatusByChips
            id={params.row.id}
            status={params.row.status}
            onChangeStatus={(item) => {
              changeEmployerStatus(params.row, item);
            }}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      flex: 1,
      minWidth: 170,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            text={new Date(params.row?.created_at).toLocaleString()}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      minWidth: 120,
      sortable: false,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useState(false);
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() =>
                dispatch(
                  setDialog({
                    id: params.row.id,
                    email: params.row.email,
                    mobile: params.row.mobile,
                    name: params.row.name,
                    company_name: params.row.company_name,
                    website: params.row.website,
                    full_address: params.row.full_address,
                    contact_email: params.row.contact_email,
                    facebook: params.row.facebook,
                    linkedin: params.row.linkedin,
                    employer_field_id: params.row.employer_field,
                    province_id: params.row.city?.province,
                    city_id: params.row.city,
                    profile_pic: params.row.profile_pic,
                  })
                )
              }
              type={"edit"}
            />
            <ColumnButton
              onClick={() => setShowConfirmDeleteModal(true)}
              type={"delete"}
            />
            <AlertDialog
              open={showConfirmDeleteModal}
              action={() => dispatch(Employer.deleteEmployer(params.row.id))}
              handleClose={() => setShowConfirmDeleteModal(false)}
              title="Delete employer"
              message={`Are you sure you want to delete ${params.row?.name}`}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(Employer.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [filters.skip, filters.take, filters.page, filters.search]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTable
          title="Employer"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          btn={"Add Employer"}
          fetchData={fetchData}
          isFilter={true}
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
          handleFilter={() => {
            dispatch(setFiltersDialog());
          }}
          handleAdd={() => dispatch(setDialog())}
        />
        <Box mt={2}>
          <DataGridTable
            rowCount={total}
            rows={data}
            loading={loading}
            page={filters.page}
            columns={columns}
            pageSize={filters.take}
            onPageChange={(newPage) => {
              dispatch(
                setFilters({
                  skip: newPage * filters.take,
                  page: newPage,
                })
              );
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(
                setFilters({
                  take: newPageSize,
                })
              );
              if (filters.skip === 0) {
                dispatch(Employer.index());
              } else {
                dispatch(
                  setFilters({
                    skip: 0,
                    page: 0,
                  })
                );
              }
            }}
          />
        </Box>
        {/* Modal */}
        {dialog && <ModalInfo />}
        {/* Fillter */}
        <Filters fetchData={fetchData} />
      </Box>
    </>
  );
};

export default Tabel;
