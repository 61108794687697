import { atom, selector } from "recoil";

// save data Select
export const dataTabelState = atom({
    key: 'dataTabelState',
    default: {
        "data": [],
        "loading": false,
        "dialog": false,
        "filters": {
            "skip": 0,
            "take": 25,
            "page": 0,
            "search": ""
        }
    },
});

// filters selector
export const TabelStateFilters = selector({
    key: "empStateFilters",
    get: ({ get }) => {
        return get(dataTabelState).filters;
    },
});