import adminNotes from "./endpoints/adminNotes";
import banner from "./endpoints/banner";
import cities from "./endpoints/cities";
import cvTemplates from "./endpoints/cvTemplates";
import feedback from "./endpoints/feedback";
import applicantStage from "./endpoints/Job/applicantStage";
import jobField from "./endpoints/Job/jobField";
import jobs from "./endpoints/Job/jobs";
import jobTitle from "./endpoints/Job/jobTitle";
import notifications from "./endpoints/notifications";
import province from "./endpoints/province";
import employer from "./endpoints/Users/employer";
import employerField from "./endpoints/Users/employerField";
import seeker from "./endpoints/Users/seeker";
import user from "./endpoints/Users/user";
import other from "./endpoints/other";
import auth from "./endpoints/auth";
import profile from "./endpoints/profile";
import statistics from "./endpoints/statistics";
const repositories = {
  auth,
  profile,
  adminNotes,
  notifications,
  feedback,
  jobTitle,
  cities,
  cvTemplates,
  banner,
  applicantStage,
  jobField,
  jobs,
  seeker,
  employerField,
  employer,
  user,
  province,
  statistics,
  other,
};

export const factory = {
  get: (name) => repositories[name],
};
