import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";

const notificationsApi = factory.get("notifications");

const initialState = {
  notifications: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: "",
    },
    dialog: false,
  },
  manageNotification: {
    dialog: false,
    loading: false,
    form: {
      title_ar: "",
      body_ar: "",
      title_en: "",
      body_en: "",
      group: "all",
    },
  },
  data: {
    show: false,
    message: "test",
    type: "success",
  },
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationAlert: (state, { payload }) => {
      state.data.show = payload.show;
      state.data.message = payload.message;
      state.data.type = payload.type;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setNotifications: (state, { payload }) => {
      state.notifications.data = payload.data;
      state.notifications.total = payload.total;
    },
    setFilters: (state, { payload }) => {
      state.notifications.filters = {
        ...state.notifications.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.notifications.filters = initialState.notifications.filters;
    },
    setDialog: (state, action) => {
      state.manageNotification.dialog = !state.manageNotification.dialog;
    },
    resetForm: (state, action) => {
      state.manageNotification.form = initialState.manageNotification.form;
    },
  },
});

export const {
  setNotificationAlert,
  setLoading,
  setNotifications,
  setFilters,
  resetFilters,
  resetForm,
  setDialog,
} = notificationSlice.actions;

export default notificationSlice.reducer;

//alert
export const showNotification = (data) => async (dispatch) => {
  try {
    data.show = true;
    dispatch(setNotificationAlert(data));
  } catch (err) {
    console.log(err);
  }
};
export const hideNotification = (data) => async (dispatch) => {
  try {
    dispatch(
      setNotificationAlert({
        message: "",
        type: data.type,
        show: false,
      })
    );
  } catch (err) {
    console.log(err);
  }
};

//api
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().notification.notifications.filters;
    dispatch(setLoading("notifications"));
    const res = await notificationsApi.index(filters);
    dispatch(setNotifications(res.data));
    dispatch(setLoading("notifications"));
  } catch (err) {
    dispatch(setLoading("notifications"));
    console.log(err);
  }
};

const create = (data, group) => async (dispatch) => {
  try {
    const _data = {
      title_en: data.title_en,
      title_ar: data.title_ar,
      body_en: data.body_en,
      body_ar: data.body_ar,
    };
    if (data.group === "guest") {
      _data.users = data.group;
    } else if (data.group !== "all" && data.group !== "guest") {
      _data.type = data.group;
    }
    dispatch(setLoading("manageNotification"));
    await notificationsApi.create(_data, group);
    dispatch(setLoading("manageNotification"));
    dispatch(
      showNotification({
        type: "success",
        message: "Notification sent successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageNotification"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteNotification = (id) => async (dispatch) => {
  try {
    await notificationsApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Notification Deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const Notifications = {
  index,
  create,
  deleteNotification,
};
