import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import SelectProvince from "../Selects/SelectProvince";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  Cities,
  resetFilters,
  setFilters,
  setFiltersDialog,
} from "../../app/slices/citiesSlicee";

export default function Filter({ fetchData, ...props }) {
  const {
    cities: { filters, dialog },
  } = useSelector((state) => state.cities);
  const dispatch = useDispatch();

  const handleSearch = () => {
    fetchData();
  };

  const handleRemoveSearch = () => {
    dispatch(resetFilters());
    dispatch(Cities.index());
  };

  const toggleDrawer = () => (event) => {
    dispatch(setFiltersDialog());
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        mt: 8,
      }}
      role="presentation"
    >
      <Grid container rowSpacing={1} sx={{ px: 1, pt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Filter options</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <SelectProvince
            value={filters.province_id}
            onChange={(e, newValue) => {
              dispatch(
                setFilters({
                  province_id: newValue,
                })
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Button
            onClick={() => handleSearch()}
            sx={{ width: "100%" }}
            variant="outlined"
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => handleRemoveSearch()}
            color="error"
            sx={{ width: "100%" }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"} className="mt-5 pt-5">
        <Drawer
          anchor={"right"}
          open={dialog}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
