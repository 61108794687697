import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const cvTemplatesApi = factory.get("cvTemplates");
const initialState = {
  cvTemplates: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
    },
    dialog: false,
  },
  manageCVTemplate: {
    form: {
      id: "",
      name: "",
      file_path: "",
      thumbnail_path: null,
    },
    dialog: false,
    loading: false,
  },
};

export const cvTemplatesSlice = createSlice({
  name: "cvTemplates",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setCVTemplates: (state, action) => {
      state.cvTemplates.data = action.payload.data;
      state.cvTemplates.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.cvTemplates.filters = {
        ...state.cvTemplates.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.cvTemplates.filters = initialState.cvTemplates.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manageCVTemplate.form.id = payload.id;
        state.manageCVTemplate.form.name = payload.name;
        state.manageCVTemplate.form.file_path = payload.file_path;
        state.manageCVTemplate.form.thumbnail_path = payload.thumbnail_path;
      } else {
        state.manageCVTemplate.form = initialState.manageCVTemplate.form;
      }
      state.manageCVTemplate.dialog = !state.manageCVTemplate.dialog;
    },
    resetForm: (state, action) => {
      state.manageCVTemplate.form = initialState.manageCVTemplate.form;
    },
  },
});

export const {
  setLoading,
  setCVTemplates,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
} = cvTemplatesSlice.actions;

export default cvTemplatesSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().cvTemplates.cvTemplates.filters;
    dispatch(setLoading("cvTemplates"));
    const res = await cvTemplatesApi.index(filters);
    dispatch(setCVTemplates(res.data));
    dispatch(setLoading("cvTemplates"));
  } catch (err) {
    dispatch(setLoading("cvTemplates"));
    console.log(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageCVTemplate"));
    await cvTemplatesApi.create(data);
    dispatch(setLoading("manageCVTemplate"));
    dispatch(
      showNotification({
        type: "success",
        message: "CV template Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageCVTemplate"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const update = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageCVTemplate"));
    await cvTemplatesApi.update(id, data);
    dispatch(setLoading("manageCVTemplate"));
    dispatch(
      showNotification({
        type: "success",
        message: "CV template updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageCVTemplate"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteCVTemplate = (id) => async (dispatch, getState) => {
  try {
    await cvTemplatesApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "CV template deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const CVTemplates = {
  index,
  deleteCVTemplate,
  create,
  update,
};
