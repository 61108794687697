import React, { forwardRef, useEffect, useState } from "react";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { factory } from "../../api/apiFactory";

const workTypeApi = factory.get("other");

export const SelectWorkType = forwardRef((props, ref) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchEducationDegreee = () => {
    setLoading(true);
    workTypeApi.indexWorkType().then((res) => {
      setOptions(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchEducationDegreee();
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Autocomplete
      ref={ref}
      options={options}
      {...props}
      getOptionLabel={(option) => `${option.name_en} - ${option.name_ar}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!props.label ? "Work type" : props.label}
          error={props.error ? props.error : null}
          helperText={props.helperText ? props.helperText : null}
          required={props.required}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
});
