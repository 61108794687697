import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
const applicantStageApi = factory.get("applicantStage");
const initialState = {
  applicantStage: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
    },
    dialog: false,
  },
  manageApplicationStage: {
    form: {
      id: "",
      name_en: "",
      name_ar: "",
    },
    dialog: false,
    loading: false,
  },
};

export const applicantStageSlice = createSlice({
  name: "applicantStage",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setApplicantStage: (state, action) => {
      state.applicantStage.data = action.payload.data;
      state.applicantStage.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.applicantStage.filters = {
        ...state.applicantStage.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.applicantStage.filters = initialState.applicantStage.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manageApplicationStage.form.id = payload.id;
        state.manageApplicationStage.form.name_en = payload.name_en;
        state.manageApplicationStage.form.name_ar = payload.name_ar;
      } else {
        state.manageApplicationStage.form =
          initialState.manageApplicationStage.form;
      }
      state.manageApplicationStage.dialog =
        !state.manageApplicationStage.dialog;
    },
    resetForm: (state, action) => {
      state.manageApplicationStage.form =
        initialState.manageApplicationStage.form;
    },
  },
});

export const {
  setLoading,
  setApplicantStage,
  setFilters,
  resetFilters,
  resetForm,
  setDialog,
} = applicantStageSlice.actions;

export default applicantStageSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().applicantStage.applicantStage.filters;
    dispatch(setLoading("applicantStage"));
    const res = await applicantStageApi.index(filters);
    dispatch(setApplicantStage(res.data));
    dispatch(setLoading("applicantStage"));
  } catch (err) {
    dispatch(setLoading("applicantStage"));
    console.log(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageApplicationStage"));
    await applicantStageApi.create(data);
    dispatch(setLoading("manageApplicationStage"));
    dispatch(
      showNotification({
        type: "success",
        message: "Stage Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageApplicationStage"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const update = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageApplicationStage"));
    await applicantStageApi.update(id, data);
    dispatch(setLoading("manageApplicationStage"));
    dispatch(
      showNotification({
        type: "success",
        message: "Stage updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageApplicationStage"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteApplicantStage = (id) => async (dispatch, getState) => {
  try {
    await applicantStageApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Stage deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const ApplicantStage = {
  index,
  create,
  update,
  deleteApplicantStage,
};
