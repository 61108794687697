import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SelectProvince from "../../Selects/SelectProvince";
import { SelectCity } from "../../Selects/SelectCity";
import { SelectEmployerField } from "../../Selects/SelectEmployerField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { Employer, setDialog } from "../../../app/slices/Users/employerSlice";
import { Controller, useForm } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DrawIcon from "@mui/icons-material/Draw";
import { LoadingButton } from "@mui/lab";

export default function ModalInfo() {
  const dispatch = useDispatch();
  const {
    manageEmployer: { dialog, loading, form },
  } = useSelector((state) => state.employer);

  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: form,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [removeImage, setRemoveImage] = useState(0);
  // set file data
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };
  const handleRemoveFile = (event) => {
    event.preventDefault();
    setSelectedFile(null);
    if (imagePreview && (form?.profile_pic || !form?.profile_pic)) {
      setImagePreview(null);
    } else if (form?.profile_pic) {
      setRemoveImage(1);
    }
  };
  const handleURL = (url) => {
    if (url) {
      let completeURL = url;
      // Check if the URL contains a protocol, if not, add 'https://' as a default
      if (!url?.includes("http://") && !url?.includes("https://")) {
        completeURL = `https://${url}`;
        return completeURL;
      }
    }
    return url;
  };

  // send request
  const onSubmit = (data) => {
    // const mobileNumber = data.get("mobile");
    // if (mobileNumber && mobileNumber.replace(/\D/g, "").length < 11) {
    //   toast.error("Mobile number must contain 11 digits");
    //   return;
    // }
    // function api request
    if (selectedFile) {
      data.profile_pic = selectedFile;
    }
    if (form.id) {
      // data form
      dispatch(Employer.update(form.id, data));
    } else {
      // formData.append("facebook", handleURL(data.get("facebook")));
      // formData.append("linkedin", handleURL(data.get("linkedin")));

      // function api request
      dispatch(Employer.create(data));
    }
  };

  // close modal
  const handleClose = () => {
    dispatch(setDialog());
  };

  // hide and show password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    reset({ ...form });
  }, [form]);

  return (
    <Dialog
      open={dialog}
      onClose={() => {
        handleClose();
      }}
      maxWidth={"md"}
      sx={{
        width: "100%",
        overflow: "hidden",
        borderRadius: 10,
      }}
    >
      <DialogTitle
        color={"primary"}
        id="draggable-dialog-title"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          borderBottom: "1px solid #bdc3c7",
        }}
      >
        {form?.id ? (
          <DrawIcon color={"primary"} />
        ) : (
          <AddCircleIcon color={"primary"} />
        )}
        {form?.id ? (
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Edit
          </Typography>
        ) : (
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Add
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        required
                        fullWidth
                        type="email"
                        label="Email"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="mobile"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        required
                        fullWidth
                        // placeholder="07*********"
                        label="Mobile"
                        type="tel"
                        // onInput={(e) => {
                        //   let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digits
                        //   // if the first digit is not equal to 0, stop taking input
                        //   if (inputValue.charAt(0) !== "0") {
                        //     e.target.value = "";
                        //   }
                        //   // if the second digit is not equal to 7, stop taking input
                        //   else if (
                        //     inputValue.charAt(1) !== "7" &&
                        //     inputValue.length >= 2
                        //   ) {
                        //     e.target.value = inputValue.slice(0, 2);
                        //   } else {
                        //     inputValue = inputValue.slice(0, 11); // Limit to 11 characters
                        //     e.target.value = inputValue;
                        //   }
                        // }}
                        // InputLabelProps={{
                        //   shrink: true,
                        // }}
                        // helperText={
                        //   !dataItem?.mobile ? "Phone number must start with 07" : ""
                        // }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        label="Name"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {!form.id && (
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          label="Password"
                          size="small"
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="company_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        label="Company"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="website"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        label="Website"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="province_id"
                    control={control}
                    render={({ field }) => (
                      <SelectProvince
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("province_id", newValue);
                          setValue("city_id", null);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="city_id"
                    control={control}
                    render={({ field }) => (
                      <SelectCity
                        value={field.value}
                        province_id={watch("province_id")?.id}
                        filterSelectedOptions
                        disabled={!watch("province_id")}
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("city_id", newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="full_address"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        label="Address"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="employer_field_id"
                    control={control}
                    render={({ field }) => (
                      <SelectEmployerField
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("employer_field_id", newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="contact_email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        label="Contact Email"
                        type="email"
                        InputProps={{
                          inputProps: {
                            pattern:
                              "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
                            title: "Please enter a valid email address",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="facebook"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        label="Facebook"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="linkedin"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        fullWidth
                        label="Linkedin"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    margin="normal"
                    component="label"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <input
                      type="file"
                      onChange={handleFileChange}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      style={{ display: "none" }}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {(imagePreview || (form?.profile_pic && !removeImage)) && (
                    <Button
                      margin="normal"
                      color="error"
                      component="label"
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      sx={{ width: "100%" }}
                      onClick={handleRemoveFile}
                    >
                      Remove File
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  {imagePreview && (
                    <Box
                      component={"img"}
                      src={imagePreview}
                      sx={{ width: "50%" }}
                    ></Box>
                  )}
                  {!imagePreview && form?.profile_pic && !removeImage && (
                    <Box
                      component={"img"}
                      src={form?.profile_pic}
                      sx={{ width: "50%" }}
                    ></Box>
                  )}
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  Close
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  sx={{ textTransform: "capitalize" }}
                >
                  {form?.id ? "Edit" : "Add"}
                </LoadingButton>
              </DialogActions>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
