import paramsFactory from "../../utils/paramsFactory";
import api from "../apiConfig";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/admin/cv-templates?${p}`);
  },
  create(data) {
    return api.post(`/api/admin/cv-templates`, data);
  },
  update(id, data) {
    return api.post(`/api/admin/cv-templates/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/admin/cv-templates/${id}`);
  },
};
