import { useEffect } from "react";
import { Box, Avatar, Button } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useState } from "react";
import BadgeIcon from "@mui/icons-material/Badge";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import {
  resetFilters,
  Seeker,
  setFilters,
  setSeekerProfileDialog,
  setFiltersDialog,
} from "../../app/slices/Users/seekerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import AlertDialog from "../AlertDialog";
import { ModalProfileSeeker } from "../ProfileSeeker/ModalProfileSeeker";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { Email } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import Filter from "./Filters";

export const Tabel = () => {
  const dispatch = useDispatch();
  const {
    seeker: { data, loading, filters, total },
    seekerProfile,
  } = useSelector((state) => state.seeker);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);

  const columns = [
    {
      field: "name_en",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      flex: 1.3,
      // minWidth: 200,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Avatar
              sx={{
                border: "2px solid #e74c3c",
              }}
              sizes="small"
              alt="Remy Sharp"
              src={params.row?.profile_pic}
            />
            <ColumnText
              text={params.row?.name ? params.row?.name : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "employer-grid--header",
      flex: 1.5,
      // minWidth: 220,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row.email ? params.row.email : "N/A"}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "employer-grid--header",
      // minWidth: 120,
      renderCell: (params) => {
        return (
          <ColumnText
            text={params.row?.mobile ? params.row?.mobile : "N/A"}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      // minWidth: 250,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        var t = "";
        if (params.row?.city?.province?.name_en && params.row?.city?.name_en) {
          t = `${params.row?.city?.province?.name_en} - ${params.row?.city?.name_en}`;
        } else if (
          params.row?.city?.province?.name_en &&
          !params.row?.city?.name_en
        ) {
          t = params.row?.city?.province?.name_en;
        } else if (
          !params.row?.city?.province?.name_en &&
          params.row?.city?.name_en
        ) {
          t = params.row?.city?.name_en;
        } else {
          t = "N/A";
        }
        return <ColumnText text={t} variant="subtitle2" fontWeight="bold" />;
      },
    },
    {
      field: "marital_status_name_en",
      headerName: "Marital Status",
      flex: 1,
      // minWidth: 110,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ColumnText
              text={
                params.row?.marital_status_name_en
                  ? params.row?.marital_status_name_en
                  : "N/A"
              }
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      // minWidth: 90,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ColumnText
              text={params.row?.gender ? params.row?.gender : "N/A"}
              variant="subtitle2"
              fontWeight="bold"
              sx={{ textTransform: "capitalize" }}
            />
          </Box>
        );
      },
    },
    {
      field: "social_type",
      headerName: "Social type",
      flex: 1,
      // minWidth: 80,
      headerClassName: "employer-grid--header",

      renderCell: (params) => (
        <Box>
          {params.row.social_type === "google" ? (
            <GoogleIcon />
          ) : params.row.social_type === "apple" ? (
            <AppleIcon />
          ) : (
            <Email />
          )}
        </Box>
      ),
    },
    {
      field: "created_at",
      headerName: "Created at",
      flex: 1,
      // minWidth: 170,
      headerClassName: "employer-grid--header",

      renderCell: (params) => {
        return (
          <ColumnText
            text={new Date(params.row?.created_at).toLocaleString()}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      // minWidth: 120,
      headerClassName: "employer-grid--header",
      sortable: false,

      renderCell: (params) => {
        const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useState(false);
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => setShowConfirmDeleteModal(true)}
              type={"delete"}
            />
            <ColumnButton
              title="User"
              color={"info"}
              onClick={() =>
                dispatch(setSeekerProfileDialog({ id: params.row.id }))
              }
              icon={<AccountBoxIcon color="info" />}
            />
            <AlertDialog
              open={showConfirmDeleteModal}
              action={() => dispatch(Seeker.deleteSeeker(params.row.id))}
              handleClose={() => setShowConfirmDeleteModal(false)}
              title="Delete Notification"
              message={`Are you sure you want to delete (${params.row.name})`}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(Seeker.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(Seeker.index());
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <>
      <Box m={2}>
        <HeaderGridTable
          title="Seekers"
          icon={<BadgeIcon size={25} color={"primary"} />}
          fetchData={fetchData}
          isFilter={true}
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
          handleFilter={() => {
            dispatch(setFiltersDialog());
          }}
        />
        <Box mt={2}>
          <DataGridTable
            rowCount={total}
            rows={data}
            loading={loading}
            page={filters.page}
            columns={columns}
            pageSize={filters.take}
            onPageChange={(newPage) => {
              dispatch(
                setFilters({
                  skip: newPage * filters.take,
                  page: newPage,
                })
              );
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(
                setFilters({
                  take: newPageSize,
                })
              );
              if (filters.skip === 0) {
                dispatch(Seeker.index());
              } else {
                dispatch(
                  setFilters({
                    skip: 0,
                    page: 0,
                  })
                );
              }
            }}
          />
        </Box>
        <Filter fetchData={fetchData} />
        {seekerProfile.dialog && (
          <ModalProfileSeeker
            handleClose={() => dispatch(setSeekerProfileDialog())}
          />
        )}
      </Box>
    </>
  );
};

export default Tabel;
