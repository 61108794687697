import { Box } from "@mui/material";
import React from "react";

export const ShapesAuth = () => {
  return (
    <div>
      <Box
        sx={{
          position: "absolute",
          width: 260,
          height: 260,
          borderRadius: "50%",
          top: -50,
          right: -50,
          backgroundColor: "#66637088",
          zIndex: 0,
          boxShadow: "0 0 20px #66637088",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: 160,
          height: 160,
          borderRadius: "50%",
          top: -50,
          left: 0,
          backgroundColor: "#00a8ff88",
          zIndex: 0,
          boxShadow: "0 0 20px #00a8ff88",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: 160,
          height: 160,
          borderRadius: "50%",
          bottom: -50,
          left: 50,
          backgroundColor: "#a6263988",
          zIndex: 0,
          boxShadow: "0 0 20px #a6263988",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: 140,
          height: 140,
          borderRadius: "50%",
          bottom: -50,
          right: 30,
          backgroundColor: "#00a8ff88",
          zIndex: 0,
          boxShadow: "0 0 20px #00a8ff88",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: 60,
          height: 60,
          borderRadius: "50%",
          bottom: 10,
          right: "calc(50vw + 90px)",
          backgroundColor: "#00a8ff88",
          zIndex: 0,
          boxShadow: "0 0 20px #00a8ff88",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: 40,
          height: 40,
          borderRadius: "50%",
          top: 86,
          right: "calc(50vw - 150px)",
          backgroundColor: "#00a8ff88",
          zIndex: 0,
          boxShadow: "0 0 20px #00a8ff88",
        }}
      />
    </div>
  );
};
