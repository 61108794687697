import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
const employerFieldApi = factory.get("employerField");

const initialState = {
  employerField: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: null,
    },
    dialog: false,
  },
  manageEmployerField: {
    form: {
      id: "",
      name_en: "",
      name_ar: "",
    },
    dialog: false,
    loading: false,
  },
};

export const employerFieldSlice = createSlice({
  name: "employerField",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setEmployerField: (state, action) => {
      state.employerField.data = action.payload.data;
      state.employerField.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.employerField.filters = {
        ...state.employerField.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.employerField.filters = initialState.employerField.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manageEmployerField.form.id = payload.id;
        state.manageEmployerField.form.name_en = payload.name_en;
        state.manageEmployerField.form.name_ar = payload.name_ar;
      } else {
        state.manageEmployerField.form = initialState.manageEmployerField.form;
      }
      state.manageEmployerField.dialog = !state.manageEmployerField.dialog;
    },
    resetForm: (state, action) => {
      state.manageEmployerField.form = initialState.manageEmployerField.form;
    },
  },
});

export const {
  setLoading,
  setEmployerField,
  setFilters,
  resetFilters,
  resetForm,
  setDialog,
} = employerFieldSlice.actions;

export default employerFieldSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().employerField.employerField.filters;
    dispatch(setLoading("employerField"));
    const res = await employerFieldApi.index(filters);
    dispatch(setEmployerField(res.data));
    dispatch(setLoading("employerField"));
  } catch (err) {
    dispatch(setLoading("employerField"));
    console.log(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageEmployerField"));
    await employerFieldApi.create(data);
    dispatch(setLoading("manageEmployerField"));
    dispatch(
      showNotification({
        type: "success",
        message: "Employer field Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageEmployerField"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const update = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageEmployerField"));
    await employerFieldApi.update(id, data);
    dispatch(setLoading("manageEmployerField"));
    dispatch(
      showNotification({
        type: "success",
        message: "Employer field updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageEmployerField"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteEmployerField = (id) => async (dispatch, getState) => {
  try {
    await employerFieldApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Employer field deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const EmployerField = {
  index,
  create,
  update,
  deleteEmployerField,
};
