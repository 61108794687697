import React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import GeneralModal from "../../GeneralModal/GeneralModal";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import { useDispatch, useSelector } from "react-redux";
import { JobField, setDialog } from "../../../app/slices/Job/jobFieldSlice";
export default function ModalInfo() {
  const dispatch = useDispatch();
  const {
    manageJobField: { form, loading, dialog },
  } = useSelector((state) => state.jobField);

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const infoData = {
      name_ar: data.get("name_ar"),
      name_en: data.get("name_en"),
    };

    // function api request
    if (form?.id) {
      dispatch(JobField.update(form.id, infoData));
    } else {
      dispatch(JobField.create(infoData));
    }
  };

  // close modal
  const handleClose = () => {
    dispatch(setDialog());
  };
  return (
    <GeneralModal
      maxWidth="md"
      open={dialog}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      type={form?.id ? "edit" : "add"}
      loading={loading}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography
          sx={{ mx: 2, mt: 1 }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          <ContactEmergencyIcon color={"primary"} />
          &nbsp; Job Field Information
        </Typography>
        <Grid container sx={{ minHeight: 120 }}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: 80,
              }}
            >
              <TextField
                sx={{ minWidth: 300 }}
                size="small"
                margin="normal"
                fullWidth
                required
                name="name_en"
                label="Name EN"
                type="text"
                id="name_en"
                defaultValue={form?.name_en}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: 80,
              }}
            >
              <TextField
                size="small"
                margin="normal"
                fullWidth
                required
                name="name_ar"
                label="Name AR"
                type="text"
                id="name_ar"
                defaultValue={form?.name_ar}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: 80,
              }}
            ></Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
