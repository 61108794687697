import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";

const profileApi = factory.get("profile");

const initialState = {
  user: {
    loading: false,
    info: {
      id: "",
      name: "",
      mobile: "",
      email: "",
      type: "",
      is_register: false,
      profile_pic: null,
      locale_code: "",
      social_type: null,
    },
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setProfile: (state, { payload }) => {
      state.user.info = payload;
    },
    setProfilePicture: (state, { payload }) => {
      state.user.info.profile_pic = payload;
    },
  },
});

export const { setProfile, setProfilePicture, setLoading } =
  profileSlice.actions;

export default profileSlice.reducer;

const myInfo = (data) => async (dispatch) => {
  try {
    console.log("myInfo");
    dispatch(setLoading("user"));
    const res = await profileApi.info();
    dispatch(setProfile(res.data.data[0]));
    dispatch(setLoading("user"));
  } catch (err) {
    dispatch(setLoading("user"));
    console.log(err);
  }
};

export const Profile = {
  myInfo,
};
