import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DrawIcon from "@mui/icons-material/Draw";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function GeneralModal({
  handleClose,
  open,
  type,
  loading,
  ...props
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        maxWidth={props.maxWidth ? props.maxWidth : "lg"}
        aria-labelledby="draggable-dialog-title"
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: 10,
        }}
      >
        <DialogTitle
          color={"primary"}
          id="draggable-dialog-title"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            borderBottom: "1px solid #bdc3c7",
          }}
        >
          {type === "remove-footer" && (
            <Tooltip title="Close">
              <IconButton color="error" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
          {type === "add" ? (
            <AddCircleIcon color={"primary"} />
          ) : type === "edit" ? (
            <DrawIcon color={"primary"} />
          ) : type === "delete" ? (
            <DeleteIcon color={"primary"} />
          ) : props.changePassword ? (
            <LockIcon color={"primary"} />
          ) : (
            props.icon
          )}
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            &nbsp;
            {type === "add"
              ? "Add"
              : type === "edit"
              ? "Edit"
              : type === "delete"
              ? "Delete"
              : type === "activate"
              ? "Admin Activation"
              : props.changePassword
              ? "Change password"
              : props.title}
          </Typography>
        </DialogTitle>
        <Box component="form" onSubmit={props.onSubmit}>
          <DialogContent sx={{ overflow: "hidden" }}>
            <DialogContentText>{props.children}</DialogContentText>
          </DialogContent>
          {type !== "remove-footer" && (
            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{ textTransform: "capitalize" }}
              >
                Close
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                sx={{ textTransform: "capitalize" }}
              >
                {type === "add"
                  ? "Add"
                  : type === "edit"
                  ? "Edit"
                  : type === "activate"
                  ? "Yes"
                  : props.changePassword
                  ? "Save"
                  : "Delete"}
              </LoadingButton>
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </div>
  );
}
