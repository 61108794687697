import paramsFactory from "../../utils/paramsFactory";
import api from "../apiConfig";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/admin/admin-notes?${p}`);
  },
  updateStatus(id, status) {
    return api.put(`/api/admin/admin-notes/${id}`, status);
  },
};
