import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import Notification from "../components/Notification/Table";

export const Notifications = () => {
  return (
    <>
      <Slider />
      <Notification />
    </>
  );
};
