import { useEffect, useMemo, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  Notifications,
  resetFilters,
  setDialog,
  setFilters,
} from "../../app/slices/notificationSlice";
import { useDebounce } from "use-debounce";
import AlertDialog from "../AlertDialog";

export const Tabel = () => {
  const dispatch = useDispatch();
  const {
    notifications: { data, filters, loading, total },
    manageNotification: { dialog },
  } = useSelector((state) => state.notification);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);

  const columns = useMemo(
    () => [
      {
        field: "title_en",
        headerName: "Title EN",
        headerClassName: "employer-grid--header",
        flex: 1,
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.title_en}>
              <Typography variant="subtitle2" fontWeight="bold">
                {params.row?.title_en}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "title_ar",
        headerName: "Title AR",
        headerClassName: "employer-grid--header",
        flex: 1,
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.title_ar}>
              <Typography variant="subtitle2" fontWeight="bold">
                {params.row?.title_ar}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "body_en",
        headerName: "Body EN",
        headerClassName: "employer-grid--header",
        flex: 1,
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.body_en}>
              <Typography variant="subtitle2" fontWeight="bold">
                {params.row?.body_en}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "body_ar",
        headerName: "Body AR",
        headerClassName: "employer-grid--header",
        flex: 1,
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.body_ar}>
              <Typography variant="subtitle2" fontWeight="bold">
                {params.row?.body_ar}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "created_by",
        headerName: "Created by",
        headerClassName: "employer-grid--header",
        flex: 1,
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.created_by}>
              <Typography variant="subtitle2" fontWeight="bold">
                {params.row?.created_by}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created At",
        headerClassName: "employer-grid--header",
        flex: 1,
        renderCell: (params) => {
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnText
                text={
                  params.row?.created_at
                    ? new Date(params.row?.created_at).toLocaleString()
                    : "N/A"
                }
                variant="subtitle2"
                fontWeight="bold"
              />
            </Box>
          );
        },
      },
      {
        field: "operations",
        headerName: "Operations",
        flex: 1,
        headerClassName: "employer-grid--header",
        sortable: false,
        renderCell: (params) => {
          const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useState(false);
          return (
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <ColumnButton
                onClick={() => setShowConfirmDeleteModal(true)}
                type={"delete"}
              />
              <AlertDialog
                open={showConfirmDeleteModal}
                action={() =>
                  dispatch(Notifications.deleteNotification(params.row.id))
                }
                handleClose={() => setShowConfirmDeleteModal(false)}
                title="Delete Notification"
                message={`Are you sure you want to delete this notification`}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  const fetchData = () => {
    dispatch(Notifications.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(Notifications.index());
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <Box m={2}>
      <HeaderGridTable
        title="Notifications"
        icon={<HomeWorkIcon size={25} color={"primary"} />}
        btn={"Send Notification"}
        fetchData={fetchData}
        handleSearch={(e) => {
          setSearch(e.target.value);
        }}
        handleAdd={() => dispatch(setDialog())}
      />
      <Box mt={2}>
        <DataGridTable
          rowCount={total}
          rows={data}
          loading={loading}
          page={filters.page}
          columns={columns}
          pageSize={filters.take}
          onPageChange={(newPage) => {
            dispatch(
              setFilters({
                skip: newPage * filters.take,
                page: newPage,
              })
            );
          }}
          onPageSizeChange={(newPageSize) => {
            dispatch(
              setFilters({
                take: newPageSize,
              })
            );
            if (filters.skip === 0) {
              dispatch(Notifications.index());
            } else {
              dispatch(
                setFilters({
                  skip: 0,
                  page: 0,
                })
              );
            }
          }}
        />
      </Box>
      {/* Modal */}
      {dialog && <ModalInfo />}
    </Box>
  );
};

export default Tabel;
