import { Box, Button, Collapse, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';

export const CardDescription = (props) => {
    const [expanded, setExpanded] = useState(false);

    const text = props?.data?.about;

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Box sx={{
            pt: 2,
            pr: 3,
            width: '100%',
        }}>
            <Typography sx={{ textAlign: { xs: 'center', md: 'initial' } }} mb={1} variant='h5' fontWeight={600}>Abstract</Typography>
            <Typography variant='body1'>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
                        {text}
                        <br />
                        <Button
                            color="primary"
                            size="small"
                            variant="text"
                            onClick={toggleExpanded}
                            sx={{ py: 0.1, px: 1, borderRadius: 5 }}
                        >
                            ... Read Less
                        </Button>
                    </Box>
                </Collapse>
                <Collapse in={!expanded}>
                    <Box sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
                        {text?.slice(0, 170)} {/* Show only 120 characters */}
                        {text?.length > 170 &&
                            <Button
                                color="primary"
                                size="small"
                                variant="text"
                                onClick={toggleExpanded}
                                sx={{ py: 0.1, px: 1, borderRadius: 5 }}
                            >
                                ... Read more
                            </Button>
                        }
                    </Box>
                </Collapse>
            </Typography>
        </Box>
    )
}
