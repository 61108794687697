import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGridTable } from "../../components/DataGridTable/DataGridTable";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  EmployerField,
  resetFilters,
  setDialog,
  setFilters,
} from "../../app/slices/Users/employerFieldSlice";
import AlertDialog from "../AlertDialog";

const Table = () => {
  const dispatch = useDispatch();
  const {
    employerField: { data, filters, loading, total },
    manageEmployerField,
  } = useSelector((state) => state.employerField);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);

  const columns = [
    {
      field: "name_en",
      headerName: "Name EN",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row?.name_en}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "name_ar",
      headerName: "Name AR",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.name_ar}
              variant="subtitle2"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useState(false);
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() =>
                dispatch(
                  setDialog({
                    id: params.row.id,
                    name_en: params.row.name_en,
                    name_ar: params.row.name_ar,
                  })
                )
              }
              type={"edit"}
            />
            <ColumnButton
              onClick={() => setShowConfirmDeleteModal(true)}
              type={"delete"}
            />
            <AlertDialog
              open={showConfirmDeleteModal}
              action={() =>
                dispatch(EmployerField.deleteEmployerField(params.row.id))
              }
              handleClose={() => setShowConfirmDeleteModal(false)}
              title="Delete job field"
              message={`Are you sure you want to delete (${params.row?.name_en} - ${params.row?.name_ar})`}
            />
          </Box>
        );
      },
    },
  ];
  const fetchData = () => {
    dispatch(EmployerField.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);
  return (
    <Box m={2}>
      <HeaderGridTable
        title="Employer Field"
        icon={<HomeWorkIcon size={25} color={"primary"} />}
        btn={"Add Employer Field"}
        fetchData={fetchData}
        handleSearch={(e) => {
          setSearch(e.target.value);
        }}
        handleAdd={() => dispatch(setDialog())}
      />
      <Box mt={2}>
        <DataGridTable
          rowCount={total}
          rows={data}
          loading={loading}
          page={filters.page}
          columns={columns}
          pageSize={filters.take}
          onPageChange={(newPage) => {
            dispatch(
              setFilters({
                skip: newPage * filters.take,
                page: newPage,
              })
            );
          }}
          onPageSizeChange={(newPageSize) => {
            dispatch(
              setFilters({
                take: newPageSize,
              })
            );
            if (filters.skip === 0) {
              dispatch(EmployerField.index());
            } else {
              dispatch(
                setFilters({
                  skip: 0,
                  page: 0,
                })
              );
            }
          }}
        />
      </Box>
      {/* Modal */}
      {manageEmployerField.dialog && <ModalInfo />}
    </Box>
  );
};

export default Table;
