import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingCircle from "./components/Others/LoadingCircle";
import { Layout } from "./layout";
import { Box } from "@mui/material";
import NotificationSnackbar from "./components/Notification";
import { useSelector } from "react-redux";

function App() {
  const {
    auth: { loading },
  } = useSelector((state) => state.auth);
  // axios.defaults.baseURL = "https://api-test.wadhifety.com/api";
  // axios.defaults.headers.common["Authorization"] =
  //   "Bearer " + Cookies.get("token");
  // const setUserInfoData = useSetRecoilState(userInfo);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get(`/user/info`)
  //     .then((res) => {
  //       console.log(res.data);
  //       setUserInfoData(res.data?.data[0]);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // }, []);

  return (
    <div className="App">
      {loading ? (
        <Box sx={{ mt: "47vh" }}>
          <LoadingCircle />
        </Box>
      ) : (
        <>
          <Layout />
          <NotificationSnackbar />
          <ToastContainer />
        </>
      )}
    </div>
  );
}

export default App;
