import api from "../apiConfig";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index() {
    return api.get(`/api/admin/statistics`);
  },
  indexProvinceStatistics() {
    return api.get(`/api/admin/statistics/province-statistics`);
  },
  indexDurationStatistics(duration) {
    return api.get(
      `/api/admin/statistics/duration-statistics?duration=${duration}`
    );
  },
};
