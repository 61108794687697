import React from "react";
import { Autocomplete, TextField } from "@mui/material";
const options = [
  {
    title: "All",
    value: null,
  },
  {
    title: "Expired",
    value: true,
  },
  {
    title: "Active",
    value: false,
  },
];

const SelectExpired = ({ ref, ...props }) => {
  return (
    <Autocomplete
      {...props}
      ref={ref}
      options={options}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          helperText={props.helpertext}
          label={props.label || "Expiery status"}
          disabled={props.disabled}
        />
      )}
    />
  );
};

export default SelectExpired;
