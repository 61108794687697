import { Menu, MenuItem, ListItemText, Chip, Typography } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

export function ChangeStatusByChips(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const status = [
        {
            id: "accepted",
            name: "Accepted",
            color: "#4caf50",
            color2: "success",
        },
        {
            id: "rejected",
            name: "Rejected",
            color: "#f44336",
            color2: "error",
        },
        {
            id: "pending",
            name: "Pending",
            color: "#ff9800",
            color2: "warning",
        },
    ];

    const filterdStatus = status.filter((item) => item.id === props.status)[0];

    return (
        <>
            <Menu
                id={`actions-${props.id}`}
                anchorEl={anchorEl}
                keepMounted
                disableAutoFocusItem
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {status.map((item, i) => (
                    <MenuItem
                        di
                        key={i}
                        onClick={() => {
                            props.onChangeStatus(item);
                            handleClose();
                        }}
                    >
                        <ListItemText primary={item.name} />
                    </MenuItem>
                ))}
            </Menu>

            <Chip
                color={filterdStatus?.color2}
                size="small"
                // disabled={loading}

                variant="outlined"
                label={
                    // loading={loading}
                    <LoadingButton loadingPosition="start">
                        <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            color={filterdStatus?.color}
                        >
                            {filterdStatus?.name}
                        </Typography>
                    </LoadingButton>
                }
                onClick={(e) => {
                    handleMenuClick(e);
                    //is pending status then show menu else not (optional)
                    // if (filterdStatus?.id === "pending") {
                    //   handleMenuClick(e);
                    // }
                }}
            />
        </>
    );
}