import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetSeekerByIdData } from "../services/api/seeker";
import Slider from "../components/Slidbar/Slidbar";
import { ProfileSeeker } from "../components/ProfileSeeker/ProfileSeeker";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProfileSeekerView = () => {
  // const location = useLocation();
  // const [data, setData] = useState(null);
  const {
    seekerProfile: { profile },
  } = useSelector((state) => state.seeker);

  // useEffect(() => {
  //   // Assuming the URL format is constant and the ID is always at the end of the URL path
  //   const path = location.pathname;
  //   const id = path.substring(path.lastIndexOf("/") + 1);
  //   console.log("ID:", id);

  //   GetSeekerByIdData(id)
  //     .then((res) => {
  //       setData(res.data[0]);
  //     })
  //     .catch((err) => {
  //       console.error("get profile seeker err", err);
  //     });
  // }, [location.pathname]);
  return (
    <>
      <Slider />
      <Box sx={{ ml: -1 }}>
        <ProfileSeeker data={profile} />
      </Box>
    </>
  );
};
