import paramsFactory from "../../../utils/paramsFactory";
import api from "../../apiConfig";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/admin/applicant-stage?${p}`);
  },
  show(id) {
    return api.get(`/api/admin/applicant-stage/${id}`);
  },
  create(data) {
    return api.post(`/api/admin/applicant-stage`, data);
  },
  update(id, data) {
    return api.put(`/api/admin/applicant-stage/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/admin/applicant-stage/${id}`);
  },
};
