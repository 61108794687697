import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { factory } from "../../api/apiFactory";

const provinceApi = factory.get("province");

export const SelectProvince = (props) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchProvince = (search = "") => {
    try {
      setLoading(true);
      provinceApi.index().then((res) => {
        setOptions(res.data.data);
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchProvince(search);
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {`${option.name_en} - ${option.name_ar}`}
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id || ""}
      getOptionLabel={(option) => `${option.name_en} - ${option.name_ar}`}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={props.helpertext}
          label={props.label || "Provinces"}
          required={props.required}
          size="small"
          onChange={(e, value) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectProvince;
