import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SelectProvince from "../../Selects/SelectProvince";
import { SelectCity } from "../../Selects/SelectCity";
import { SelectCompany } from "../../Selects/SelectCompany";
import { SelectJobTitle } from "../../Selects/SelectJobTitle";
import { SelectWorkType } from "../../Selects/SelectWorkType";
import SelectGender from "../../Selects/SelectGender";
import { SelectEducationsDegree } from "../../Selects/SelectEducationsDegree";
import BusinessIcon from "@mui/icons-material/Business";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import SelectCurrency from "../../Selects/SelectCurrency";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Jobs, setDialog } from "../../../app/slices/Job/jobsSlice";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DrawIcon from "@mui/icons-material/Draw";
import { LoadingButton } from "@mui/lab";

export default function ModalInfo() {
  const dispatch = useDispatch();
  const {
    manageJob: { dialog, loading, form },
  } = useSelector((state) => state.jobs);

  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: form,
  });

  const onSubmit = (data) => {
    const { province_id, ...rest } = data;
    if (form.id) {
      dispatch(Jobs.update(form.id, rest));
    } else {
      dispatch(Jobs.create(rest));
    }
  };

  // close modal
  const handleClose = () => {
    dispatch(setDialog());
  };

  useEffect(() => {
    reset({ ...form });
  }, [form]);

  return (
    <Dialog
      open={dialog}
      maxWidth={"lg"}
      sx={{
        width: "100%",
        overflow: "hidden",
        borderRadius: 10,
      }}
    >
      <DialogTitle
        color={"primary"}
        id="draggable-dialog-title"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          borderBottom: "1px solid #bdc3c7",
        }}
      >
        {form?.id ? (
          <DrawIcon color={"primary"} />
        ) : (
          <AddCircleIcon color={"primary"} />
        )}
        {form?.id ? (
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Edit
          </Typography>
        ) : (
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Add
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} mt={1} mb={2}>
                <Grid item xs={12}>
                  <Typography
                    sx={{ my: 1, display: "flex", alignItems: "center" }}
                    variant="h5"
                    fontSize={18}
                    fontWeight={500}
                  >
                    <BusinessIcon color={"primary"} />
                    &nbsp; Company Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="company_id"
                    control={control}
                    render={({ field }) => (
                      <SelectCompany
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) =>
                          setValue("company_id", newValue)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="employer_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        label="Employer name"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ my: 1 }}
                    variant="h5"
                    fontSize={18}
                    fontWeight={500}
                  >
                    <ContactEmergencyIcon color={"primary"} />
                    &nbsp; Job Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="province_id"
                    control={control}
                    render={({ field }) => (
                      <SelectProvince
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("province_id", newValue);
                          setValue("city_id", null);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="city_id"
                    control={control}
                    render={({ field }) => (
                      <SelectCity
                        value={field.value}
                        province_id={watch("province_id")?.id}
                        filterSelectedOptions
                        disabled={!watch("province_id")}
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("city_id", newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="education_degree_id"
                    control={control}
                    render={({ field }) => (
                      <SelectEducationsDegree
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("education_degree_id", newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="work_type_id"
                    control={control}
                    render={({ field }) => (
                      <SelectWorkType
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("work_type_id", newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="work_days"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        label="Work days"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="job_title_id"
                    control={control}
                    render={({ field }) => (
                      <SelectJobTitle
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("job_title_id", newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="due_to"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        label="Due to"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="other_job_title"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        label="Other job title"
                        disabled={watch("job_title_id")?.id !== 1}
                        required={watch("job_title_id")?.id === 1}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <SelectGender
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("gender", newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="full_address"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        label="Address"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Controller
                    name="salary_from"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        label="Salary from"
                        type="number"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Controller
                    name="salary_to"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        label="Salary to"
                        type="number"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Controller
                    name="currency"
                    control={control}
                    render={({ field }) => (
                      <SelectCurrency
                        value={field.value}
                        filterSelectedOptions
                        required={true}
                        onChange={(e, newValue) => {
                          setValue("currency", newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="medium"
                        fullWidth
                        label="Description"
                        multiline
                        minRows={8}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  Close
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  sx={{ textTransform: "capitalize" }}
                >
                  {form?.id ? "Edit" : "Add"}
                </LoadingButton>
              </DialogActions>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
