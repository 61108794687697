import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
const jobFieldApi = factory.get("jobField");
const initialState = {
  jobField: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: "",
    },
    dialog: false,
  },
  manageJobField: {
    form: {
      id: "",
      name_en: "",
      name_ar: "",
    },
    dialog: false,
    loading: false,
  },
};

export const jobFieldSlice = createSlice({
  name: "jobField",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setJobField: (state, action) => {
      state.jobField.data = action.payload.data;
      state.jobField.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.jobField.filters = {
        ...state.jobField.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.jobField.filters = initialState.jobField.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manageJobField.form.id = payload.id;
        state.manageJobField.form.name_en = payload.name_en;
        state.manageJobField.form.name_ar = payload.name_ar;
      } else {
        state.manageJobField.form = initialState.manageJobField.form;
      }
      state.manageJobField.dialog = !state.manageJobField.dialog;
    },
    resetForm: (state, action) => {
      state.manageJobField.form = initialState.manageJobField.form;
    },
  },
});

export const {
  setLoading,
  setJobField,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
} = jobFieldSlice.actions;

export default jobFieldSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().jobField.jobField.filters;
    dispatch(setLoading("jobField"));
    const res = await jobFieldApi.index(filters);
    dispatch(setJobField(res.data));
    dispatch(setLoading("jobField"));
  } catch (err) {
    dispatch(setLoading("jobField"));
    console.log(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageJobField"));
    await jobFieldApi.create(data);
    dispatch(setLoading("manageJobField"));
    dispatch(
      showNotification({
        type: "success",
        message: "Job field Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageJobField"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const update = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageJobField"));
    await jobFieldApi.update(id, data);
    dispatch(setLoading("manageJobField"));
    dispatch(
      showNotification({
        type: "success",
        message: "Job field updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageJobField"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteJobField = (id) => async (dispatch, getState) => {
  try {
    await jobFieldApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Job field deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const JobField = {
  index,
  create,
  update,
  deleteJobField,
};
