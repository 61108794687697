import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import img_logo from "../../assets/images/logo.png";
import { InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import TitleIcon from "@mui/icons-material/Title";
import { SlidbarItem } from "./SlidbarItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BadgeIcon from "@mui/icons-material/Badge";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { MenuProfile } from "../Auth/MenuProfile";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import MapIcon from "@mui/icons-material/Map";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ArticleIcon from "@mui/icons-material/Article";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import FeedbackIcon from "@mui/icons-material/Feedback";
import EngineeringIcon from "@mui/icons-material/Engineering";
import StairsIcon from "@mui/icons-material/Stairs";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../app/slices/appSlice";
import dayjs from "dayjs";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": { ...openedMixin(theme) },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Slider = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.app.is_drawer_opened);
  const openDrop = useSelector((state) => state.app.collapse);
  const { user } = useSelector((state) => state.profile);

  const dataSlidbar = [
    {
      icon: <HomeIcon className="menu-icon" />,
      text: "Home",
      path: "/",
    },
    {
      icon: <AccountCircleIcon className="menu-icon" />,
      text: "Users",
      child: [
        {
          icon: <AdminPanelSettingsIcon className="menu-icon" />,
          text: "Admins",
          path: "/admins",
        },
        {
          icon: <AssignmentIndIcon className="menu-icon" />,
          text: "Employer",
          path: "/employer",
        },
        {
          icon: <BusinessCenterIcon className="menu-icon" />,
          text: "Employer Field",
          path: "/employer-field",
        },
        {
          icon: <BadgeIcon className="menu-icon" />,
          text: "Seeker",
          path: "/seeker",
        },
      ],
    },

    {
      icon: <WorkIcon className="menu-icon" />,
      text: "Job",
      child: [
        {
          icon: <HomeWorkIcon className="menu-icon" />,
          text: "Jobs",
          path: "/jobs",
        },
        {
          icon: <TitleIcon className="menu-icon" />,
          text: "Job Title",
          path: "/job-title",
        },
        {
          icon: <EngineeringIcon className="menu-icon" />,
          text: "Job Field",
          path: "/job-field",
        },
        {
          icon: <StairsIcon className="menu-icon" />,
          text: "Applicant Stages",
          path: "/applicant-stages",
        },
      ],
    },
    {
      icon: <ViewCarouselIcon className="menu-icon" />,
      text: "Banner",
      path: "/banner",
    },
    {
      icon: <MapIcon className="menu-icon" />,
      text: "Cities",
      path: "/cities",
    },
    {
      icon: <NotificationsActiveIcon className="menu-icon" />,
      text: "Notifications",
      path: "/notifications",
    },
    {
      icon: <ArticleIcon className="menu-icon" />,
      text: "Cv Templates",
      path: "/cv-templates",
    },
    {
      icon: <NoteAltIcon className="menu-icon" />,
      text: "Admin Notes",
      path: "/admin-notes",
    },
    {
      icon: <FeedbackIcon className="menu-icon" />,
      text: "Feedback",
      path: "/feedback",
    },
  ];

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ background: "#ecf0f1", display: user.info ? "flex" : "none" }}
      >
        <Toolbar sx={{ display: "flex" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(setDrawer())}
            edge="start"
            sx={{
              position: "absolute",
              left: 26,
              marginRight: 5,
              borderRadius: "50%",
              background: "#a62639",
              "&:hover": {
                background: "#a62639",
              },
            }}
            hover
          >
            {open ? (
              theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )
            ) : (
              <MenuIcon />
            )}
          </IconButton>
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              left: 60,
            }}
          >
            <a href="/">
              <img
                style={{ width: 70, marginLeft: 5, marginRight: 5 }}
                src={img_logo}
                alt="logo"
              />
            </a>
          </Box>
          <Box
            sx={{
              display: "none",
              position: "absolute",
              right: "calc(50vw - 250px)",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 500,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: 5,
            }}
            hover
          >
            <MenuProfile />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ display: user.info ? "flex" : "none" }}
      >
        <Divider />
        <Box>
          <ListItem
            disablePadding
            sx={{ display: "block", border: "none", mt: 8 }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: "50%",
                    background: "#b2bec3",
                  }}
                >
                  <AccountCircleIcon className="menu-icon-user" />
                </Box>
              </ListItemIcon>
              <Box sx={{ width: open ? "100%" : "0px", overflow: "hidden" }}>
                <ListItemText primary={user.info.name} />
                <Typography variant="caption" display="block" gutterBottom>
                  Admin
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>
        </Box>
        <List
          sx={{
            height: "100%",
            overflowY: "auto",
            paddingBottom: "30px",
          }}
        >
          {dataSlidbar.map((item, index) => {
            return (
              <SlidbarItem
                key={index}
                index={index}
                open={open}
                openDrop={openDrop}
                text={item.text}
                path={item.path}
                child={item.child}
                icon={item.icon}
              />
            );
          })}
        </List>
        <Divider />
        <ListItem
          disablePadding
          sx={{
            display: "block",
            background: "#ffffff",
            border: "none",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
          }}
        >
          <Box
            sx={{
              minHeight: 28,
              justifyContent: open ? "initial" : "center",
              px: 0,
              display: "flex",
            }}
          >
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              sx={{
                display: open ? "flex" : "none",
                textAlign: "center",
                m: "auto",
                width: "fit-content",
                overflow: "hidden",

                justifyContent: "center",
              }}
            >
              Copyrights &copy; {dayjs().year()}
            </Typography>
            <Box
              sx={{
                minWidth: 0,
                justifyContent: "center",
                border: "none",
                m: "auto",
                position: "absolute",
                bottom: 0,
              }}
            >
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{
                  display: open ? "none" : "flex",
                  textAlign: "center",
                  m: "auto",
                  width: "fit-content",
                  overflow: "hidden",
                }}
              >
                &copy; {dayjs().year()}
              </Typography>
            </Box>
          </Box>
        </ListItem>
      </Drawer>
    </Box>
  );
};

export default Slider;
