import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import anim_lock from "../../assets/animations/anim_lock.json";
import { toast } from "react-toastify";
import Lottie from "lottie-web";
import { useEffect } from "react";
import useMutate from "../../hooks/useMutate";
import StepperForgetPassword from "./components/StepperForgetPassword";
import { ShapesAuth } from "../Auth/ShapesAuth";
import { useForm } from "react-hook-form";
const ForgotPasswordForm = () => {
  // handle forgot password
  const { mutate } = useMutate("/auth/send-reset-password", {
    action: "post",
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
    onError: (err) => {
      toast.error(err.response.data?.errors[0]);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#lock-anim"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: anim_lock,
    });
  }, []);

  // handle form submir
  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        background: "#ffffff44",
        backdropFilter: "blur(10px)",
      }}
    >
      <ShapesAuth />
      <Grid
        container
        sx={{
          maxWidth: { xs: "90vw", md: "70vw" },
          height: "70vh",
          my: "15vh",
          mx: "auto",
          justifyContent: "center",
          alignItems: "center",
          background: { xs: "#ffffff", lg: "#ffffff44" },
          padding: 0,
          boxShadow: "1px 4px 10px #dcdde1",
          borderRadius: "10px",
          overflow: "hidden",
          backdropFilter: "blur(20px)",
        }}
      >
        <CssBaseline />
        <Grid
          item
          md={false}
          lg={7}
          sx={{
            background: "#ffffff44",
            height: "100%",
            overflow: "hidden",
            display: { xs: "none", lg: "block" },
          }}
        >
          <Box sx={{ mt: "2vh" }}>
            <Box
              sx={{
                width: 360,
                height: 360,
                overflow: "hidden",
                mx: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span id="lock-anim"></span>
            </Box>
            <StepperForgetPassword step={0} />
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          lg={5}
          component={Paper}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            background: "#ffffff44",
          }}
        >
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: "none",
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              fontWeight={500}
              sx={{
                mb: 3,
              }}
            >
              Forgot Password
            </Typography>
            <Typography
              component="p"
              variant="p"
              fontWeight={500}
              sx={{
                mb: 3,
              }}
            >
              Enter your email so a code will be sent to you.
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={"Email"}
                autoComplete="email"
                autoFocus
                {...register("email")}
                error={errors?.email?.message ? true : false}
                helperText={errors?.email?.message}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                style={{ padding: 12 }}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPasswordForm;
