import React from "react";
import { Box, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import TitleIcon from "@mui/icons-material/Title";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GradeIcon from "@mui/icons-material/Grade";
import img_certificate from "../../assets/images/icons/certificate.png";

export const CardCertificate = (props) => {
  return (
    <Card
      sx={{
        display: "flex",
        boxShadow: "1px 4px 10px rgba(0,0,0,.15)",
        justifyContent: "space-between",
        borderRadius: 3,
        my: 2,
        mr: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            component="div"
            variant="h6"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <TitleIcon color={"primary"} />
            &nbsp;
            {props.resource}
          </Typography>
          <Typography mx={3.7} variant="subtitle2" color="text.secondary">
            {props.name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ display: "flex", alignItems: "center", mt: 1 }}
          >
            <CalendarMonthIcon />
            &nbsp;
            {props.date?.split(" ")[0]}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ display: "flex", alignItems: "center", mt: 1 }}
          >
            <GradeIcon />
            &nbsp;
            {props.description}
          </Typography>
        </CardContent>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#ecf0f1",
          width: 160,
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: 80 }}
          image={img_certificate}
          alt="Cirtificate"
        />
      </Box>
    </Card>
  );
};
