import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import SelectProvince from "../Selects/SelectProvince";
import SelectStatus from "../Selects/SelectStatus";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  Employer,
  resetFilters,
  setFilters,
  setFiltersDialog,
} from "../../app/slices/Users/employerSlice";

export default function Filter(props) {
  const {
    employer: { filters, dialog },
  } = useSelector((state) => state.employer);
  const dispatch = useDispatch();

  const handleSearch = () => {
    props.fetchData();
  };

  const handleRemoveSearch = () => {
    dispatch(resetFilters());
    dispatch(Employer.index());
  };

  const toggleDrawer = () => (event) => {
    dispatch(setFiltersDialog());
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        mt: 8,
      }}
      role="presentation"
    >
      <Box sx={{ m: 1, position: "relative", height: "86vh" }}>
        <Typography mt={2} variant="h6">
          Filter Advancis
        </Typography>
        {/* <TextField
          size="small"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={props.emailSearch ? props.emailSearch : ""}
          onChange={(e) => props.setEmailSearch(e.target.value)}
          autoFocus
        />
        <TextField
          size="small"
          margin="normal"
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          autoComplete="phone"
          value={props.phoneSearch ? props.phoneSearch : ""}
          onChange={(e) => props.setPhoneSearch(e.target.value)}
        /> */}
        <SelectProvince
          value={filters.province_id}
          sx={{ my: 1 }}
          onChange={(e, newValue) =>
            dispatch(setFilters({ province_id: newValue }))
          }
        />
        <SelectStatus
          value={filters.status}
          onChange={(e, newValue) => dispatch(setFilters({ status: newValue }))}
        />
        <Box sx={{ position: "absolute", bottom: 0, right: 0, left: 0 }}>
          <Button
            onClick={() => handleSearch()}
            sx={{ width: "100%" }}
            variant="outlined"
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            onClick={() => handleRemoveSearch()}
            color="error"
            sx={{ width: "100%", mt: 1 }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"} className="mt-5 pt-5">
        <Drawer anchor={"right"} open={dialog} onClose={toggleDrawer()}>
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
