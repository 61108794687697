import Slider from "../components/Slidbar/Slidbar";
import { Box } from "@mui/material";
import { Table } from "../components/CvTemplates/Tabel";

const CvTemplates = () => {
  return (
    <>
      <Slider />
      <Box sx={{ my: 5, px: 4 }}>
        <Table />
      </Box>
    </>
  );
};

export default CvTemplates;
