import api from "../apiConfig";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  indexEducationDegree() {
    return api.get(`/api/admin/educations-degree`);
  },
  indexWorkType() {
    return api.get(`/api/admin/work-type`);
  },
};
